import config from "../config";
import { get, put, Delete, post } from "../http";
import storage from "../storage";

export async function resetPassword(token) {
  const headers = {
    "Content-Type": "application/json",
  };
  const body = { user: token };

  return await put(
    `${config.usersMicroserviceUrlApi}/users/resetPassword`,
    headers,
    body
  );
}

async function getUserStatus() {
  const headers = {
    "Content-Type": "application/json",
  };
  return await get(`${config.usersMicroserviceUrlApi}/system/status`, headers);
}

async function updateRequestsLoginByCode(data, token) {
  const body = {
    ...data,
  };
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };
  return await put(
    `${config.usersMicroserviceUrlApi}/users/requestLogin/update`,
    headers,
    body
  );
}

async function remove(username, token) {
  const headers = {
    authorization: `Bearer ${token}`,
  };

  return await Delete(
    `${config.usersMicroserviceUrlApi}/users?username=${username}`,
    headers
  );
}

async function getUserById(data, token) {
  const headers = {
    authorization: `Bearer ${token}`,
  };
  return await get(
    `${config.usersMicroserviceUrlApi}/users/?id=${data.id}`,
    headers
  );
}
async function getUsersByIdCompany(
  id,
  page,
  limit,
  token,
  searchText,
  searchField,
  orderBy,
  order
) {
  const headers = { authorization: `Bearer ${token}` };
  return await get(
    `${config.usersMicroserviceUrlApi}/users/getByIdCompany?id=${id}&page=${page}&limit=${limit}&searchText=${searchText}&searchField=${searchField}&orderBy=${orderBy}&order=${order}`,
    headers
  );
}

async function getUsersBySubsidiary(id, token) {
  const headers = { authorization: `Bearer ${token}` };
  return await get(
    `${config.usersMicroserviceUrlApi}/users/getBySubsidiary?subsidiaryId=${id}`,
    headers
  );
}

async function getInternalUsersByIdCompany(id, token) {
  const headers = { authorization: `Bearer ${token}` };
  return await get(
    `${config.usersMicroserviceUrlApi}/users/getInternalByIdCompany?id=${id}`,
    headers
  );
}

async function getRequestsLoginByIdCompany(idCompany, token) {
  const headers = { authorization: `Bearer ${token}` };
  return await get(
    `${config.usersMicroserviceUrlApi}/users/requestLogin?idCompany${idCompany}`,
    headers
  );
}

async function getByUsername(username, token, companyId) {
  const headers = { authorization: `Bearer ${token}` };
  return await get(
    `${config.usersMicroserviceUrlApi}/users/getByUsername?username=${username}&idCompany=${companyId}`,
    headers
  );
}

async function registerUser(user) {
  const headers = {
    "Content-Type": "application/json",
  };
  const body = { user };

  return await post(
    `${config.usersMicroserviceUrlApi}/users/register`,
    headers,
    body
  );
}

async function update(user, token) {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };
  const body = { user };

  return await put(`${config.usersMicroserviceUrlApi}/users`, headers, body);
}

async function confirm(username) {
  const headers = { "Content-Type": "application/json" };
  const body = { ...username };

  return await fetch(
    `${config.usersMicroserviceUrlApi}/users/confirm`,
    headers,
    body
  );
}

async function login(email, password, country, idCompany) {
  return new Promise(async (resolve, reject) => {
    const headers = { "Content-Type": "application/json" };
    const body = { email, password, country, idCompany };
    await post(
      `${config.usersMicroserviceUrlApi}/users/authenticateByEmail`,
      headers,
      body
    )
      .then((user) => {
        // storage.set({ user });
        return resolve(user);
      })
      .catch((err) => {
        if (!err || err.includes("TypeError: Failed to fetch")) {
          const error = "Por favor verifique la conexión a internet.";
          reject(error);
        }
        reject(err);
      });
  });
}

function logout() {
  const lumarketo = storage.get();
  if (lumarketo) storage.set({ user: undefined });
}

async function getAll(token) {
  const headers = { authorization: `Bearer ${token}` };
  return await get(
    `${config.usersMicroserviceUrlApi}/users/getAllUsers`,
    headers
  );
}

async function sendUsersCSV(id, email, name, token) {
  const headers = { authorization: `Bearer ${token}` };
  return await post(
    `${config.usersMicroserviceUrlApi}/sendUsersCSV`,

    headers,
    {
      email,
      name,
      companyId: id,
    }
  );
}

const userService = {
  getAll,
  logout,
  login,
  confirm,
  update,
  registerUser,
  getByUsername,
  getRequestsLoginByIdCompany,
  getUserById,
  getUserStatus,
  remove,
  updateRequestsLoginByCode,
  getUsersByIdCompany,
  resetPassword,
  getInternalUsersByIdCompany,
  getUsersBySubsidiary,
  sendUsersCSV,
};

export default userService;
