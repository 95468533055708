import React, { Fragment, memo } from "react";
import {
  Page,
  Text,
  Document,
  StyleSheet,
  View,
  Font,
  usePDF,
  Image,
} from "@react-pdf/renderer";
import FileSaver from "file-saver";
import printJS from "print-js";
import uuid from "react-uuid";
import lumaUtils from "@lumarketo/luma-utils";

export const OrderPDFBase = ({
  title,
  address,
  tlf,
  orderNumber,
  products,
  total,
  userTlf,
  fullName,
  userAddress,
  shippingCost,
  shipping,
  createdDate,
}) => {
  const logo =
    "https://luma-photos.s3.amazonaws.com/content/logo-nubefood-recortado.png";

  const productDetails = () => {
    return products.map((product) => {
      const additionalAmount = product.additionals.reduce(
        (acc, add) => acc + add.price * (add.quantity || 1),
        0
      );

      return (
        <Fragment key={product.productInfo._id}>
          <View
            style={{
              ...styles.bodyTable,
            }}
          >
            <Text style={styles.bodyText}>{`${
              product.quantity
            } - ${lumaUtils.stringUtils.slugifyText(
              product.productInfo.Name,
              " ",
              false
            )} - ${lumaUtils.stringUtils.slugifyText(
              product.skuInfo.Size,
              " ",
              false
            )}`}</Text>
            <View style={styles.scdBodyTable}>
              <Text style={styles.bodyValue}>
                {lumaUtils.numberUtils.formatNumber(
                  product.skuInfo.Price + additionalAmount
                )}
              </Text>
              <Text style={styles.bodyValue}>
                {lumaUtils.numberUtils.formatNumber(
                  (product.bestPrice + additionalAmount) * product.quantity
                )}
              </Text>
            </View>
          </View>
          {product.additionals.map((item) => (
            <View style={{ ...styles.bodyTable, marginLeft: 2 }}>
              <Text style={{ ...styles.bodyText }}>
                {item.quantity || 1} x{" "}
                {lumaUtils.stringUtils.slugifyText(
                  item.description,
                  " ",
                  false
                )}
              </Text>
              <View style={{ ...styles.scdBodyTable }}>
                <Text style={{ ...styles.bodyValue }}>
                  {lumaUtils.numberUtils.formatNumber(item.price)}
                </Text>
                <Text style={{ ...styles.bodyValue }}>
                  {lumaUtils.numberUtils.formatNumber(item.price * product.quantity)}
                </Text>
              </View>
            </View>
          ))}
          <View style={{ ...styles.bodyTable, marginLeft: 2 }}>
            <Text
              style={{
                ...styles.bodyText,
                fontSize: 3,
                textTransform: "capitalize",
              }}
            >
              {lumaUtils.stringUtils.slugifyText(
                product.instructions,
                " ",
                false
              )}
            </Text>
          </View>
        </Fragment>
      );
    });
  };

  return (
    <Document
      author="Nubefood"
      title={`pedido-${orderNumber}-${Date.now()}}`}
      pageMode="fullScreen"
      pageLayout="singlePage"
    >
      <Page style={{ ...styles.body }} size={[72.1, 297]}>
        <View
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Image src={logo} style={styles.image} />
          <Text style={styles.title}>{title}</Text>
          <Text style={styles.description}>{address}</Text>
          <Text style={styles.description}>{tlf}</Text>
        </View>
        <View
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Text style={styles.orderNumber}>
            {" "}
            Nro. Pedido:{" "}
            <Text style={styles.orderNumberValue}>{orderNumber}</Text>
          </Text>
        </View>
        <View
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Text style={styles.orderNumber}>
            {" "}
            Fecha del Pedido:{" "}
            <Text style={styles.orderNumberValue}>{createdDate}</Text>
          </Text>
        </View>
        <View
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Text style={styles.userInfo}>
            {" "}
            Cliente: <Text style={styles.userInfoValue}>{fullName}</Text>
          </Text>
        </View>
        <View
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Text style={styles.userInfo}>
            {" "}
            Dirección: <Text style={styles.userInfoValue}>{userAddress}</Text>
          </Text>
        </View>
        <View
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Text style={{ ...styles.userInfo }}>
            {" "}
            Tlf: <Text style={styles.userInfoValue}>{userTlf}</Text>
          </Text>
        </View>
        <View
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Text style={{ ...styles.userInfo, marginBottom: 5 }}>
            {" "}
            Método de envío:{" "}
            <Text style={styles.userInfoValue}>{shipping}</Text>
          </Text>
        </View>
        <View style={styles.headerTable}>
          <Text style={styles.headerTitle}>Producto</Text>
          <View style={styles.scdHeaderTable}>
            <Text style={styles.scdHeaderTitle}>P. Unit.</Text>
            <Text style={styles.scdHeaderTitle}>Total</Text>
          </View>
        </View>
        <Text style={styles.divider} />
        {productDetails()}
        <Text style={styles.divider} />
        <View style={styles.headerTable}>
          <Text style={styles.headerTitle}>ENVIO</Text>
          <View style={styles.scdHeaderTable}>
            <Text style={styles.scdHeaderTitle}></Text>
            <Text style={styles.scdHeaderTitle}>
              {lumaUtils.numberUtils.formatNumber(shippingCost)}
            </Text>
          </View>
        </View>
        <View style={styles.headerTable}>
          <Text style={styles.headerTitle}>TOTAL</Text>
          <View style={styles.scdHeaderTable}>
            <Text style={styles.scdHeaderTitle}></Text>
            <Text style={styles.scdHeaderTitle}>
              {lumaUtils.numberUtils.formatNumber(total)}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export const OrderPDFBase2 = ({
  title,
  address,
  tlf,
  orderNumber,
  products,
  total,
  userTlf,
  fullName,
  userAddress,
  shippingCost,
  shipping,
  createdDate,
}) => {
  const logo =
    "https://luma-photos.s3.amazonaws.com/content/logo-nubefood-recortado.png";
  return (
    <Document
      author="Nubefood"
      title={`pedido-${orderNumber}-${Date.now()}}`}
      pageMode="fullScreen"
      pageLayout="singlePage"
    >
      <Page style={{ ...styles2.body }} size={[72.1, 297]}>
        <View
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Image src={logo} style={styles2.image} />
          <Text style={styles2.title}>{title}</Text>
          <Text style={styles2.description}>{address}</Text>
          <Text style={styles2.description}>{tlf}</Text>
        </View>
        <View
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Text style={styles2.orderNumber}>
            {" "}
            Nro. Pedido:{" "}
            <Text style={styles2.orderNumberValue}>{orderNumber}</Text>
          </Text>
        </View>
        <View
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Text style={styles.orderNumber}>
            {" "}
            Fecha del Pedido:{" "}
            <Text style={styles.orderNumberValue}>{createdDate}</Text>
          </Text>
        </View>
        <View
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Text style={styles2.userInfo}>
            {" "}
            Cliente: <Text style={styles2.userInfoValue}>{fullName}</Text>
          </Text>
        </View>
        <View
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Text style={styles2.userInfo}>
            {" "}
            Dirección: <Text style={styles2.userInfoValue}>{userAddress}</Text>
          </Text>
        </View>
        <View
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Text style={{ ...styles2.userInfo }}>
            {" "}
            Tlf: <Text style={styles2.userInfoValue}>{userTlf}</Text>
          </Text>
        </View>
        <View
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Text style={{ ...styles2.userInfo, marginBottom: 5 }}>
            {" "}
            Método de envío:{" "}
            <Text style={styles2.userInfoValue}>{shipping}</Text>
          </Text>
        </View>
        <View style={styles2.headerTable}>
          <Text style={styles2.headerTitle}>Producto</Text>
        </View>
        <Text style={styles2.divider} />
        {products.map((product) => (
          <Fragment key={product.productInfo._id}>
            <View
              style={{
                ...styles2.bodyTable,
              }}
            >
              <Text style={styles2.bodyText}>{`${
                product.quantity
              } - ${lumaUtils.stringUtils.slugifyText(
                product.productInfo.Name,
                " ",
                false
              )} - ${lumaUtils.stringUtils.slugifyText(
                product.skuInfo.Size,
                " ",
                false
              )}`}</Text>
            </View>
            {product.additionals.map((item) => (
              <View style={{ ...styles2.bodyTable, marginLeft: 2 }}>
                <Text style={{ ...styles2.bodyText }}>
                  {item.quantity || 1} x{" "}
                  {lumaUtils.stringUtils.slugifyText(
                    item.description,
                    " ",
                    false
                  )}
                </Text>
              </View>
            ))}
            <View style={{ ...styles2.bodyTable, marginLeft: 2 }}>
              <Text
                style={{
                  ...styles2.bodyText,
                  fontSize: 2,
                  textTransform: "capitalize",
                }}
              >
                {lumaUtils.stringUtils.slugifyText(
                  product.instructions,
                  " ",
                  false
                )}
              </Text>
            </View>
          </Fragment>
        ))}
        <Text style={styles2.divider} />
        <View style={styles2.headerTable}>
          <Text style={styles2.headerTitle}>ENVIO</Text>
          <View style={styles2.scdHeaderTable}>
            <Text style={styles2.scdHeaderTitle}></Text>
            <Text style={styles2.scdHeaderTitle}>
              {lumaUtils.numberUtils.formatNumber(shippingCost)}
            </Text>
          </View>
        </View>
        <View style={styles2.headerTable}>
          <Text style={styles2.headerTitle}>TOTAL</Text>
          <View style={styles2.scdHeaderTable}>
            <Text style={styles2.scdHeaderTitle}></Text>
            <Text style={styles2.scdHeaderTitle}>
              {lumaUtils.numberUtils.formatNumber(total)}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export const PDFOrder = memo(
  ({
    title,
    address,
    tlf,
    orderNumber,
    products,
    total,
    userTlf,
    fullName,
    userAddress,
    companyLogo,
    shippingCost,
    shipping,
    distance,
    buttonText = "Aceptar",
    buttonStyle = "",
    onClick = () => null,
    disabled = false,
    model = 1,
  }) => {
    const getModel = (model) => {
      const models = {
        1: (
          <OrderPDFBase
            {...{
              title,
              address,
              tlf,
              orderNumber,
              products,
              total,
              userTlf,
              fullName,
              userAddress,
              companyLogo,
              shippingCost,
              shipping,
              distance,
            }}
          />
        ),
        2: (
          <OrderPDFBase2
            {...{
              title,
              address,
              tlf,
              orderNumber,
              products,
              total,
              userTlf,
              fullName,
              userAddress,
              companyLogo,
              shippingCost,
              shipping,
              distance,
            }}
          />
        ),
      };
      return models[model];
    };
    const [instance, updateInstance] = usePDF({
      document: getModel(model),
    });
    
    if (instance.loading) return <div>Cargando...</div>;

    if (instance.error) return <div>Algo salio mal: {instance.error}</div>;

    return instance.loading ? (
      <button disabled className={buttonStyle}>
        {buttonText}
      </button>
    ) : (
      <button
        disabled={disabled}
        className={buttonStyle}
        onClick={() => {
          updateInstance(getModel(model));
          printJS({
            printable: instance.url,
            type: "pdf",
            showModal: true,
            documentTitle: `pedido-${orderNumber}-${Date.now()}.pdf`,
            frameId: uuid(),
          });
          onClick();
        }}
      >
        {buttonText}
      </button>
    );
  },
  (p, n) =>
    p.title === n.title &&
    p.address === n.address &&
    p.tlf === n.tlf &&
    p.orderNumber === n.orderNumber &&
    p.products === n.products &&
    p.total === n.total &&
    p.userTlf === n.userTlf &&
    p.fullName === n.fullName &&
    p.userAddress === n.userAddress &&
    p.companyLogo === n.companyLogo &&
    p.shippingCost === n.shippingCost &&
    p.shipping === n.shipping &&
    p.distance === n.distance &&
    p.buttonText === n.buttonText &&
    p.buttonStyle === n.buttonStyle &&
    p.onClick === n.onClick &&
    p.disabled === n.disabled &&
    p.model === n.model
);

export const LinkPDFOrder = ({
  title,
  address,
  tlf,
  orderNumber,
  products,
  total,
  userTlf,
  fullName,
  userAddress,
  companyLogo,
  shipping,
  distance,
  shippingCost,
  createdDate,
  linkText = "Aceptar",
  linkStyle = "",
  onClick = () => null,
  disabled = false,
  model = 1,
}) => {
  const getModel = (model) => {
    const models = {
      1: (
        <OrderPDFBase
          {...{
            title,
            address,
            tlf,
            orderNumber,
            products,
            total,
            userTlf,
            fullName,
            userAddress,
            companyLogo,
            shippingCost,
            shipping,
            distance,
            createdDate,
          }}
        />
      ),
      2: (
        <OrderPDFBase2
          {...{
            title,
            address,
            tlf,
            orderNumber,
            products,
            total,
            userTlf,
            fullName,
            userAddress,
            companyLogo,
            shippingCost,
            shipping,
            distance,
            createdDate,
          }}
        />
      ),
    };
    return models[model];
  };
  const [instance, updateInstance] = usePDF({
    document: getModel(model),
  });

  if (instance.error)
    return <div>Algo salío mal: {instance.error.message}</div>;
  if (instance.loading) return <div>Cargando...</div>;

  return instance.loading ? (
    <p disabled className={linkStyle}>
      {linkText}
    </p>
  ) : (
    <p
      disabled={disabled}
      className={linkStyle}
      onClick={() => {
        // printJS({
        //   printable: instance.url,
        //   type: "pdf",
        //   showModal: true,
        //   documentTitle: `pedido-${orderNumber}.pdf`,
        // });
        updateInstance(getModel(model));
        FileSaver.saveAs(
          instance.blob,
          `pedido-${orderNumber}-${Date.now()}.pdf`
        );
        onClick();
      }}
    >
      {linkText}
    </p>
  );
};

// Create styles2
const styles = StyleSheet.create({
  body: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingHorizontal: 5,
    width: "100%",
    height: "50vh",
  },
  image: {
    marginVertical: 1,
    marginHorizontal: 1,
    // justifyContent: "center",
    margin: "0 auto",
    // width: ,
    height: 8,
    // aspectRatio: 3/2,
    // objectFit: "contain",
    // mixBlendMode: "color-burn",
  },
  title: {
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Oswald",
    marginBottom: 2,
    marginTop: 0,
  },
  orderNumber: {
    textAlign: "left",
    margin: "5px 0",
    fontSize: 5,
    fontFamily: "Oswald",
  },
  orderNumberValue: {
    textAlign: "left",
    margin: "5px 0",
    fontSize: 5,
    fontFamily: "Times-Roman",
  },
  userInfo: {
    textAlign: "left",
    fontSize: 5,
    fontFamily: "Oswald",
  },
  userInfoValue: {
    textAlign: "left",
    fontSize: 5,
    fontFamily: "Times-Roman",
  },
  userInfoValueNA: {
    textAlign: "left",
    fontSize: 4,
    fontFamily: "Times-Roman",
  },
  description: {
    fontSize: 5,
    textAlign: "center",
  },
  subtitle: {
    fontSize: 9,
    margin: 12,
    fontFamily: "Oswald",
  },
  text: {
    margin: 2,
    fontSize: 16,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  divider: {
    borderBottom: "1px dashed #000",
    margin: "1px 0px",
    display: "flex",
  },
  table: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  headerTable: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  scdHeaderTable: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    width: "100%",
  },
  scdHeaderTitle: {
    fontSize: 5,
    fontFamily: "Oswald",
    marginBottom: 2,
    textAlign: "right",
  },
  headerTitle: {
    fontSize: 5,
    fontFamily: "Oswald",
    marginBottom: 2,
    width: "100%",
  },

  bodyTable: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  scdBodyTable: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    width: "100%",
  },
  bodyText: {
    textTransform: "uppercase",
    fontSize: 4.5,
    fontFamily: "Times-Roman",
    marginBottom: 5,
    marginRight: 8,
    width: "100%",
  },
  bodyValue: {
    textTransform: "uppercase",
    fontSize: 4.5,
    fontFamily: "Times-Roman",
    marginBottom: 5,
    textAlign: "right",
  },
});

const styles2 = StyleSheet.create({
  body: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingHorizontal: 5,
    width: "100%",
    height: "50vh",
  },
  image: {
    marginVertical: 1,
    marginHorizontal: 1,
    // justifyContent: "center",
    margin: "0 auto",
    // width: ,
    height: 8,
    // aspectRatio: 3/2,
    // objectFit: "contain",
    // mixBlendMode: "color-burn",
  },
  title: {
    fontSize: 5,
    textAlign: "center",
    fontFamily: "Oswald",
    marginBottom: 1,
  },
  orderNumber: {
    textAlign: "left",
    margin: "2px 0 0 0",
    fontSize: 3,
    fontFamily: "Oswald",
  },
  orderNumberValue: {
    textAlign: "left",
    margin: "2px 0 0 0",
    fontSize: 3,
    fontFamily: "Times-Roman",
  },
  userInfo: {
    textAlign: "left",
    fontSize: 3,
    fontFamily: "Oswald",
  },
  userInfoValue: {
    textAlign: "left",
    fontSize: 3,
    fontFamily: "Times-Roman",
  },
  userInfoValueNA: {
    textAlign: "left",
    fontSize: 3,
    fontFamily: "Times-Roman",
  },
  description: {
    fontSize: 3,
    textAlign: "center",
  },
  subtitle: {
    fontSize: 4,
    margin: 12,
    fontFamily: "Oswald",
  },
  text: {
    margin: 2,
    fontSize: 16,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  divider: {
    borderBottom: "0.5px dashed #000",
    margin: "1px 0px",
    display: "flex",
  },
  table: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  headerTable: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  scdHeaderTable: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    width: "100%",
  },
  scdHeaderTitle: {
    fontSize: 3,
    fontFamily: "Oswald",
    marginBottom: 1,
    textAlign: "right",
  },
  headerTitle: {
    fontSize: 3,
    fontFamily: "Oswald",
    marginBottom: 1,
    width: "100%",
  },

  bodyTable: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  scdBodyTable: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    width: "100%",
  },
  bodyText: {
    textTransform: "uppercase",
    fontSize: 2.5,
    fontFamily: "Oswald",
    marginBottom: 1,
    marginRight: 5,
    width: "100%",
  },
  bodyValue: {
    textTransform: "uppercase",
    fontSize: 4.5,
    fontFamily: "Oswald",
    marginBottom: 5,
    textAlign: "right",
  },
});

Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});

export default PDFOrder;
