const orderConstants = {
  ORDER_STATUS: {
    pending: {
      text: "Esperando confirmación de método de pago",
      code: "pending",
      color: "#30A4DD",
    },
    refund: {
      text: "Reembolsada",
      code: "refund",
      color: "#52A70A",
    },
    "payment-approved": {
      text: "El pago fue aprobado",
      code: "payment-approved",
      color: "#52A70A",
    },
    "payment-canceled": {
      text: "El pago fue cancelado o ocurrio un error",
      code: "payment-canceled",
      color: "#ff0000",
    },
    "payment-pending": {
      text: "En espera del pago",
      code: "payment-pending",
      color: "#E2AD48",
    },
    "picker-pending": {
      text: "Pendiente",
      code: "picker-pending",
      color: "#30A4DD",
    },
    inProcess: {
      text: "En proceso",
      code: "inProcess",
      color: "#30A4DD",
    },
    ready: {
      text: "Lista para despachar",
      code: "ready",
      color: "#52A70A",
    },
    routed: {
      text: "Se esta procesando el envío",
      code: "routed",
      color: "#52A70A",
    },
    dispatched: {
      text: "Entregado",
      code: "dispatched",
      color: "#52A70A",
    },
    cancel: {
      text: "Cancelado",
      code: "cancel",
      color: "#ff0000",
    },
    shippingError: {
      text: "Error en el envío",
      code: "shippingError",
      color: "#ff0000",
    },
    na: {
      text: "N/A",
      code: "N/A",
    },

    cabify_ready: {
      text: "La parcela ha sido creada.",
      code: "ready",
    },
    cancelledShipping: {
      text: "Envío cancelado",
      code: "cancelledShipping",
      color: "#ff0000",
    },
    cabify_qualifiedforpickup: {
      text: "En busqueda de un conductor para iniciar la entrega.",
      code: "cabify_qualifiedforpickup",
    },
    cabify_onroutetopickup: {
      text: "La entrega ha comenzado y un conductor se dirige al punto de recogida.",
      code: "cabify_onroutetopickup",
    },
    cabify_pickingup: {
      text: "El conductor ha llegado al punto de recogida y está listo para recoger el paquete.",
      code: "cabify_pickingup",
    },
    cabify_intransit: {
      text: "El paquete está en el vehículo.",
      code: "cabify_intransit",
    },
    cabify_delivering: {
      text: "El conductor ha llegado al punto de entrega y está listo para la entrega.",
      code: "cabify_delivering",
    },
    cabify_delivered: {
      text: "El paquete ha sido entregado con éxito.",
      code: "cabify_delivered",
    },
    cabify_returning: {
      text: "No se encontró el destinatario y el paquete está regresando al punto de recogida.",
      code: "cabify_returning",
    },
    cabify_returned: {
      text: "No se encontró el destinatario y el paquete se devolvió con éxito",
      code: "cabify_returned",
    },
    cabify_incident: {
      text: "Hubo un incidente durante el proceso de devolución.",
      code: "cabify_incident",
    },
    cabify_requestercancel: {
      text: "El solicitante ha cancelado la entrega.",
      code: "cabify_requestercancel",
    },
    cabify_internalcancel: {
      text: "La entrega ha sido cancelada.",
      code: "cabify_internalcancel",
    },
    cabify_pickupfailed: {
      text: "No fue posible recoger el paquete.",
      code: "cabify_pickupfailed",
    },
    uber_pending: {
      text: "Envío solicitado y en espera de asignación de conductor",
      code: "uber_pending",
    },
    uber_pickup: {
      text: "El conductor está en camino a recoger el envío",
      code: "uber_pickup",
    },
    uber_pickup_complete: {
      text: "El conductor ha recogido el envío",
      code: "uber_pickup_complete",
    },
    uber_dropoff: {
      text: "El conductor está en camino a entregar el envío",
      code: "uber_dropoff",
    },
    uber_delivered: {
      text: "El envío ha sido entregado",
      code: "uber_delivered",
    },
    uber_canceled: {
      text: "El envío ha sido cancelado",
      code: "uber_canceled",
    },
    uber_returned: {
      text: "El envío ha sido devuelto",
      code: "uber_returned",
    },
    pedidosya_REJECTED:
    {
      text: "Pedido de envío solicitado pero rechazado debido a datos no válidos.",
      code: "pedidosya_REJECTED",
    },
    pedidosya_CONFIRMED:
    {
      text: "Orden de envío confirmada y en espera.",
      code: "pedidosya_CONFIRMED",
    },
    pedidosya_IN_PROGRESS:
    {
      text: "Se ha asignado el transporte.",
      code: "pedidosya_IN_PROGRESS",
    },
    pedidosya_NEAR_PICKUP:
    {
      text: "El transporte está cerca del punto de recogida.",
      code: "pedidosya_NEAR_PICKUP",
    },
    pedidosya_PICKED_UP:
    {
      text: "Transporte recogió los artículos del pedido.",
      code: "pedidosya_PICKED_UP",
    },
    pedidosya_NEAR_DROPOFF:
    {
      text: "El transporte está más cerca del punto de entrega.",
      code: "pedidosya_NEAR_DROPOFF",
    },
    pedidosya_COMPLETED:
    {
      text: "Transporte ha entregado los artículos.",
      code: "pedidosya_COMPLETED",
    },
    pedidosya_CANCELLED:
    {
      text: "Pedido de envío cancelado por cualquier motivo.",
      code: "pedidosya_CANCELLED",
    },
  },
  ORDER_WITHOUT_SHIPPING_STATUS: {
    pending: {
      text: "Esperando confirmación de método de pago",
      code: "pending",
      color: "#30A4DD",
    },
    refund: {
      text: "Reembolsada",
      code: "refund",
      color: "#52A70A",
    },
    "payment-approved": {
      text: "El pago fue aprobado",
      code: "payment-approved",
      color: "#52A70A",
    },
    "payment-canceled": {
      text: "El pago fue cancelado o ocurrio un error",
      code: "payment-canceled",
      color: "#ff0000",
    },
    "payment-pending": {
      text: "En espera del pago",
      code: "payment-pending",
      color: "#E2AD48",
    },
    "picker-pending": {
      text: "Pendiente",
      code: "picker-pending",
      color: "#30A4DD",
    },
    inProcess: {
      text: "En proceso",
      code: "inProcess",
      color: "#30A4DD",
    },
    ready: {
      text: "Listo",
      code: "ready",
      color: "#52A70A",
    },
    routed: {
      text: "Listo para retiro",
      code: "routed",
      color: "#52A70A",
    },
    dispatched: {
      text: "Retirado",
      code: "dispatched",
      color: "#52A70A",
    },
    cancel: {
      text: "Cancelado",
      code: "cancel",
      color: "#ff0000",
    },
    shippingError: {
      text: "Error en el envío",
      code: "shippingError",
      color: "#ff0000",
    },
    na: {
      text: "N/A",
      code: "N/A",
    },
  },
  INCIDENTS: {
    stolenOrderDeliveryCreateShipping: "Reenvío de pedido",
    orderIncomplete: "Pedido incompleto, reembolso parcial",
    orderCancel: "Pedido cancelado y reembolsado",
    stolenOrderDeliveryTotalRefund: "Reembolsado por pedido robado",
    shippingCancel: 'Envío cancelado',
    newShipping: 'Nuevo envío',
    sendLoyaltyPoints: 'Enviar puntos de compras',
  },
};

export default orderConstants;
