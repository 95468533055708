/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect, useLayoutEffect, useState } from "react";
import { deburr } from "lodash";

import {
  Grid,
  MenuItem,
  TextField,
  Typography,
  Switch as SwitchMUI,
  InputAdornment,
  OutlinedInput,
  ButtonGroup,
  Button,
  FormControl,
  Select,
} from "@material-ui/core";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import FormatAlignCenterIcon from "@material-ui/icons/FormatAlignCenter";
import FormatAlignRightIcon from "@material-ui/icons/FormatAlignRight";
import FormatAlignLeftIcon from "@material-ui/icons/FormatAlignLeft";

import ColorPicker from "../../pickers/color";
import { getSuggestions } from "../../../utils";
import SuggestionInput from "../../suggestion";

import SliderComponent from "../../slider/";
import ImagePlusButton from "../../button/plusImg";
import styles from "./styles.module.css";
import classNames from "classnames";
import PlusVideoButton from "../../button/plusVideo";
import { useSelector } from "react-redux";
//import { selectProducts } from "../../../reducers/productSlice";
import MultiAutocompleteComponent from "../../selects/multiAutocomplete.component";
import { useSnackbar } from "notistack";
import { Notification } from "../../../containers/notification";
import uuid from "react-uuid";
import GradientColor from "../../pickers/gradientColor";
import lumaUtils from "@lumarketo/luma-utils";
import useGetProducts from "../../../utils/hooks/products/useGetProducts";
import { selectCompany } from "../../../reducers/companySlice";
import { selectUser } from "../../../reducers/userSlice";

export const Text = ({
  classes,
  value,
  handleChange,
  title = "Texto",
  id = "",
}) => {
  const [oldValue, setOldValue] = useState("");
  const [text, setText] = useState("");

  /*   useEffect(() => {
    if (value !== oldValue && value !== "" && value !== text) {
      setOldValue(value);
      setText(value);
    }
  }, [value]); */
  useEffect(() => {
    if (value !== oldValue && value !== text) {
      setOldValue(value);
      setText(value);
    }
  }, [value]);

  useEffect(() => {
    let timer1 = setTimeout(() => handler(), 300);

    return () => {
      clearTimeout(timer1);
    };
  }, [text]);

  /*  const handler = () => {
    if (value !== "" && value !== text) handleChange(text);
  }; */
  const handler = () => {
    if (value !== text) handleChange(text);
  };

  return (
    <MuiThemeProvider theme={theme}>
      <Grid container direction="row" alignItems="center">
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            align="left"
            className={classes.titleInput}
          >
            {title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <MuiThemeProvider theme={theme}>
            <TextField
              id={id}
              error={text === ""}
              className={classes.componentTextField}
              value={text}
              margin="normal"
              variant="outlined"
              helperText={text === "" ? "Recuerde completar" : ""}
              onChange={(e) => setText(e.target.value)}
              // onMouseLeave={() => handler()}
              // onMouseEnter={() => handler()}
            />
          </MuiThemeProvider>
        </Grid>
      </Grid>
    </MuiThemeProvider>
  );
};

export const NumberInput = ({
  classes,
  value,
  handleChange,
  title = "Tamaño de texto",
  adorment = "px",
}) => {
  return (
    <MuiThemeProvider theme={theme}>
      <Grid container direction="row" alignItems="center">
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            align="left"
            className={classes.titleInput}
          >
            {title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <MuiThemeProvider theme={theme}>
            <OutlinedInput
              type="number"
              endAdornment={
                adorment !== "" ? (
                  <InputAdornment position="end">{adorment}</InputAdornment>
                ) : null
              }
              error={value === ""}
              className={classes.componentTextField}
              value={value}
              helperText={value === "" ? "Recuerde indicar el tamaño" : ""}
              onChange={(e) => {
                if (Number(e.target.value) > 0 && !isNaN(e.target.value))
                  handleChange(Number(e.target.value));
                else handleChange(0);
              }}
            />
          </MuiThemeProvider>
        </Grid>
      </Grid>
    </MuiThemeProvider>
  );
};

export const Align = ({
  classes,
  value,
  handleChange,
  loading,
  title = "Alineación",
}) => {
  return (
    <MuiThemeProvider theme={theme}>
      <Grid container direction="row" alignItems="center">
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            align="left"
            className={classes.titleInput}
          >
            {title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ButtonGroup
            color="primary"
            aria-label="outlined primary button group"
            size="small"
            style={{ height: 25 }}
          >
            <Button
              className={value === "left" ? styles.selectedAlign : ""}
              onClick={() => handleChange("left")}
            >
              <FormatAlignLeftIcon size="small" />
            </Button>
            <Button
              className={value === "center" ? styles.selectedAlign : ""}
              onClick={() => handleChange("center")}
            >
              <FormatAlignCenterIcon size="small" />
            </Button>
            <Button
              className={value === "right" ? styles.selectedAlign : ""}
              onClick={() => handleChange("right")}
            >
              <FormatAlignRightIcon size="small" />
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    </MuiThemeProvider>
  );
};

export const Color = ({
  classes,
  value,
  handleChange,
  title = "Color",
  hex = true,
}) => {
  return (
    <Grid container direction="row" alignItems="center">
      <Grid item xs={12}>
        <Typography
          variant="subtitle1"
          align="left"
          className={classes.titleInput}
        >
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ColorPicker
          callback={(color) =>
            handleChange(lumaUtils.stringUtils.getRgbToHex(color.rgb))
          }
          rgb={
            hex
              ? lumaUtils.numberUtils.convertColorHexToRgb(value)
              : lumaUtils.stringUtils.getColorObjectRGB(value)
          }
        />
      </Grid>
    </Grid>
  );
};

export const GradientColorInput = ({
  classes,
  value,
  handleChange,
  title = "Color",
}) => {
  return (
    <Grid container direction="row" alignItems="center">
      <Grid item xs={12}>
        <Typography
          variant="subtitle1"
          align="left"
          className={classes.titleInput}
        >
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <GradientColor
          callback={(color) => handleChange(color)}
          value={value}
        />
      </Grid>
    </Grid>
  );
};

export const Margin = ({ classes, value, handleChange, title = "Margen" }) => {
  const [up, setUp] = useState(0);
  const [down, setDown] = useState(0);
  const [left, setLeft] = useState(0);
  const [right, setRight] = useState(0);
  const [firstRender, setFirstRender] = useState(false);

  useEffect(() => {
    const margin = `${up} ${right} ${down} ${left}`;
    if (handleChange && margin !== value && firstRender) handleChange(margin);
  }, [up, down, left, right]);

  useEffect(() => {
    if (value && value !== "") {
      const splitted = value.split(" ");
      if (splitted.length >= 4) {
        const splittedUp = splitted[0].trim();
        const splittedRight = splitted[1].trim();
        const splittedDown = splitted[2].trim();
        const splittedLeft = splitted[3].trim();

        if (splittedUp !== up) setUp(splittedUp);
        if (splittedRight !== right) setRight(splittedRight);
        if (splittedDown !== down) setDown(splittedDown);
        if (splittedLeft !== left) setLeft(splittedLeft);
      }
      setFirstRender(true);
    }
  }, [value]);

  return (
    <Grid container direction="row" alignItems="center">
      <Grid item xs={12}>
        <Typography
          variant="subtitle1"
          align="left"
          className={classes.titleInput}
        >
          {title}
        </Typography>
      </Grid>
      <Grid container direction="row">
        <MuiThemeProvider theme={alignTheme}>
          <Grid item xs={3}>
            <MuiThemeProvider theme={firstAlignTheme}>
              <input
                error={value === ""}
                className={classNames(
                  styles.componentTextFieldMargin,
                  classes.componentTextField,
                  styles.input1
                )}
                value={up}
                margin="normal"
                variant="outlined"
                helperText={value === "" ? "Recuerde indicar el valor" : ""}
                onChange={(e) => {
                  setUp(e.target.value);
                }}
              />
            </MuiThemeProvider>

            <Typography variant="body2" className={classes.marginHelperText}>
              Arriba
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <input
              error={value === ""}
              className={classNames(
                styles.componentTextFieldMargin,
                classes.componentTextField,
                styles.input2
              )}
              value={right}
              margin="normal"
              variant="outlined"
              helperText={value === "" ? "Recuerde indicar el valor" : ""}
              onChange={(e) => {
                setRight(e.target.value);
              }}
            />{" "}
            <Typography variant="body2" className={classes.marginHelperText}>
              Derecha
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <input
              error={value === ""}
              className={classNames(
                styles.componentTextFieldMargin,
                classes.componentTextField,
                styles.input3
              )}
              value={down}
              margin="normal"
              variant="outlined"
              helperText={value === "" ? "Recuerde indicar el valor" : ""}
              onChange={(e) => {
                setDown(e.target.value);
              }}
            />{" "}
            <Typography variant="body2" className={classes.marginHelperText}>
              Abajo
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <MuiThemeProvider theme={lastAlignTheme}>
              <input
                error={value === ""}
                className={classNames(
                  styles.componentTextFieldMargin,
                  classes.componentTextField,
                  styles.input4
                )}
                value={left}
                margin="normal"
                variant="outlined"
                helperText={value === "" ? "Recuerde indicar el valor" : ""}
                onChange={(e) => {
                  setLeft(e.target.value);
                }}
              />
            </MuiThemeProvider>

            <Typography variant="body2" className={classes.marginHelperText}>
              Izquierda
            </Typography>
          </Grid>
        </MuiThemeProvider>
      </Grid>
    </Grid>
  );
};

export const Padding = ({
  classes,
  value,
  handleChange,
  title = "Margen de relleno(padding)",
}) => (
  <Margin
    {...{
      classes,
      value,
      handleChange,
      title,
    }}
  />
);

export const Link = ({
  classes,
  value,
  handleChange,
  collections,
  company,
  products,
  loading,
  title = "Link",
  categories,
}) => {
  return (
    <MuiThemeProvider theme={theme}>
      <Grid container direction="row" alignItems="center">
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            align="left"
            className={classes.titleInput}
          >
            {title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <MuiThemeProvider theme={theme}>
            <SuggestionInput
              disabled={loading}
              placeholder="Puede seleccionar rutas, colecciones, marcas, categorias y subcategorias"
              suggestions={getSuggestions(
                collections,
                company.Brands,
                categories,
                products
              )}
              styles={styles}
              handleChange={(value) => handleChange(value)}
              callback={(value) => handleChange(value)}
              value={value}
            />
          </MuiThemeProvider>
        </Grid>
      </Grid>
    </MuiThemeProvider>
  );
};

export const NumberSlider = ({
  classes,
  value,
  handleChange,
  title = "Tamaño",
}) => (
  <Grid container direction="row" alignItems="center">
    <Grid item xs={12}>
      <Typography
        variant="subtitle1"
        align="left"
        className={classes.titleInput}
      >
        {title}
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <SliderComponent
        value={Number(value)}
        min={0}
        max={100}
        onChange={(e, newValue) => handleChange(newValue)}
        aria-labelledby="continuous-slider"
      />
    </Grid>
  </Grid>
);

export const Img = ({
  path,
  alt,
  classes,
  handleChange,
  id = "",
  useTitle = true,
}) => (
  <Grid container direction="row" alignItems="center">
    {useTitle && (
      <Grid item xs={12}>
        <Typography
          variant="subtitle1"
          align="left"
          className={classes.titleInput}
        >
          Imagen
        </Typography>
      </Grid>
    )}
    <Grid item xs={12} style={{ justifyContent: "center" }}>
      <ImagePlusButton
        withHistory={false}
        id={id}
        width={200}
        height={200}
        path={path}
        alt={alt}
        callback={({ location, key }) => handleChange(location, key)}
      />
    </Grid>
  </Grid>
);

export const Weight = ({
  classes,
  value,
  handleChange,
  loading,
  title = "Tipo de letra",
}) => {
  return (
    <MuiThemeProvider theme={theme}>
      <Grid container direction="row" alignItems="center">
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            align="left"
            className={classes.titleInput}
          >
            {title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <MuiThemeProvider theme={theme}>
            <TextField
              select
              value={Number(value)}
              className={classes.componentTextField}
              margin="normal"
              variant="outlined"
              disabled={loading}
              onChange={(e) => handleChange(e.target.value)}
            >
              <MenuItem value={400}>Normal</MenuItem>
              <MenuItem value={700}>Negrita</MenuItem>
            </TextField>
          </MuiThemeProvider>
        </Grid>
      </Grid>
    </MuiThemeProvider>
  );
};

export const Video = ({ classes, loading, handleChange, path }) => (
  <Grid container direction="row" alignItems="center">
    <Grid item xs={12}>
      <Typography
        variant="subtitle1"
        align="left"
        className={classes.titleInput}
      >
        Video:
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <MuiThemeProvider theme={theme}>
        <PlusVideoButton {...{ width: 200, handleChange, path }} />
      </MuiThemeProvider>
    </Grid>
  </Grid>
);

export const Switch = ({
  classes,
  handleChange,
  value,
  title = "Usar controles",
}) => (
  <Grid container direction="row" alignItems="center">
    <Grid item xs={9}>
      <Typography
        variant="subtitle1"
        align="left"
        className={classes ? classes.titleInput : ""}
      >
        {title}
      </Typography>
    </Grid>
    <Grid item xs={3}>
      <SwitchMUI
        checked={value}
        onChange={(e) => handleChange(e.target.checked)}
        name="Controls"
        color="primary"
      />
    </Grid>
  </Grid>
);

export const Direction = ({
  classes,
  handleChange,
  value,
  title = "Dirección",
}) => (
  <MuiThemeProvider theme={theme}>
    <Grid container direction="row" alignItems="center">
      <Grid item xs={12}>
        <Typography
          variant="subtitle1"
          align="left"
          className={classes.titleInput}
        >
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControl variant="outlined" className={classes.componentTextField}>
          <Select
            native
            value={value}
            onChange={(e) => handleChange(e.target.value)}
            inputProps={{
              name: "direction",
              id: "outlined-age-native-simple",
            }}
          >
            <option value="Right">Derecha</option>
            <option value="Left">Izquierda</option>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  </MuiThemeProvider>
);

export const MultiSKUInput = ({
  classes,
  skus,
  handleChange,
  title = "SKUs",
  maxQty = 4,
}) => {
  // const products = useSelector(selectProducts);
  const [selected, setSelect] = useState([]);
  const [prevSelected, setPrevSelect] = useState([]);
  const [updated, setUpdated] = useState(false);
  const [autocompleteInputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const company = useSelector(selectCompany);
  const user = useSelector(selectUser);
  const { products } = useGetProducts(
    company,
    user?.token /* , [refreshKey] */
  );

  useEffect(() => {
    if (selected.length === 0 && prevSelected.length === 0) {
      setSelect(
        skus
          .map((sku) => {
            return suggestions.find((s) => {
              return s.value === sku;
            });
          })
          .filter((item) => item && item.label && item.value)
      );
      setPrevSelect(
        skus
          .map((sku) => suggestions.find((s) => s.value === sku))
          .filter((item) => item && item.label && item.value)
      );
    }
  }, [skus, suggestions]);

  useEffect(() => {
    function getSuggestionsProducts() {
      let suggestions = [];
      if (products !== undefined) {
        products.forEach((Product) => {
          if (
            suggestions.find((item) => item.label === Product.Name) ===
              undefined &&
            Product.Show &&
            Product.Children &&
            Product.Children[0] &&
            Product.Children[0].Stocks.find((item) => item.qty > 0)
          ) {
            // suggestions.push({
            //   label: Product.Name,
            //   value:
            //     Product.Name
            // });
            suggestions.push({
              label: Product.Name,
              value:
                Product.Children[0] && Product.Children[0].SKU
                  ? Product.Children[0].SKU
                  : "",
            });
            Product.Children.forEach((child) => {
              suggestions.push({
                label: child.SKU,
                value: child && child.SKU ? child.SKU : "",
              });
            });
          }
        });
        setSuggestions(suggestions.filter((item) => item && item.label));
      }
    }
    getSuggestionsProducts();
  }, [products, skus]);

  useEffect(() => {
    const jsonSkus = JSON.stringify(skus);
    const selectedSkus = selected.map((item) => item.value);
    const jsonSelected = JSON.stringify(selectedSkus);
    if (jsonSelected !== jsonSkus && updated) {
      handleChange(selectedSkus);
      setPrevSelect(skus);
      setUpdated(false);
    }
  }, [selected, skus]);

  const getSuggestions = (value) => {
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;
    return inputLength === 0
      ? []
      : suggestions.filter((suggestion) => {
          const keep =
            suggestion && suggestion.label && suggestion.label !== ""
              ? count < 5 &&
                suggestion.label.slice(0, inputLength).toLowerCase() ===
                  inputValue
              : false;

          if (keep) {
            count += 1;
          }
          return keep;
        });
  };

  const handleKeyDown = (event) => {
    if (
      selected.length &&
      !autocompleteInputValue.length &&
      event.key === "Backspace"
    ) {
      setSelect(selected.slice(0, selected.length - 1));
      setUpdated(true);
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleChangeAutocomplete = (item) => {
    if (selected.length <= maxQty - 1) {
      var selectedItem = [];
      if (selected.indexOf(item) === -1) {
        selectedItem = [...selected, item];
      }
      setSelect(selectedItem);
      setInputValue("");
      setUpdated(true);
    } else {
      enqueueSnackbar(
        Notification({
          text: "Ha alcanzado el máximo de productos para el componente.",
          variant: "error",
          withDetails: false,
        })
      );
    }
  };
  const handleDelete = (item) => () => {
    var items = [...selected];
    const index = items.findIndex((a) => a.value === item);
    if (index > -1) items.splice(index, 1);
    setSelect(items);
    setUpdated(true);
  };

  return (
    <MuiThemeProvider theme={theme}>
      <Grid container direction="row">
        {title && title !== "" && (
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              align="left"
              className={classes.titleInput}
            >
              {title}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <MultiAutocompleteComponent
            handleInputChange={handleInputChange}
            handleChange={handleChangeAutocomplete}
            handleDelete={handleDelete}
            getSuggestions={getSuggestions}
            selectedItem={selected}
            handleKeyDown={handleKeyDown}
            inputValue={autocompleteInputValue}
            placeholder="Buscar por SKU"
          />
        </Grid>
      </Grid>
    </MuiThemeProvider>
  );
};

export const SelectInput = ({
  classes,
  handleChange,
  value,
  values,
  title = "Dirección",
}) => (
  <MuiThemeProvider theme={theme}>
    <Grid container direction="row" alignItems="center">
      <Grid item xs={12}>
        <Typography
          variant="subtitle1"
          align="left"
          className={classes.titleInput}
        >
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControl variant="outlined" className={classes.componentTextField}>
          <Select
            native
            value={value}
            onChange={(e) => handleChange(e.target.value)}
            inputProps={{
              name: title,
              id: "outlined-age-native-simple",
            }}
          >
            {values.map((item) => (
              <option key={item?.value} value={item?.value}>
                {item?.label}
              </option>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  </MuiThemeProvider>
);

export const onClickToElement = (
  id,
  subindex,
  handleChangeSubIndex,
  useFocus = true,
  useScrollTo = true,
  useClick = true
) => {
  var d = document.getElementById(id);
  console.log(id);
  if (d) {
    if (useScrollTo)
      d.scrollIntoView({
        behavior: "auto",
        block: "start",
        inline: "start",
      });
    // d.scrollIntoViewIfNeeded(false);
    if (useFocus) d.focus();
    if (useClick) d.click();
    handleChangeSubIndex(subindex);
  }
};

export const EditText = memo(
  ({
    value,
    handleChange,
    component,
    style = {},
    cssStyle = null,
    onClick = () => null,
    multiline = false,
    multilineCols = 35,
    multilineRows = 2,
  }) => {
    const [oldValue, setOldValue] = useState("");
    const [text, setText] = useState("");
    const [edit, setEdit] = useState(false);

    useEffect(() => {
      if (value !== oldValue && value !== "" && value !== text) {
        setOldValue(value);
        setText(value);
      }
    }, [value]);

    useEffect(() => {
      if (!edit && oldValue !== "" && text !== value) {
        handleChange(text);
        setOldValue(text);
      }
    }, [edit]);

    useLayoutEffect(() => {
      // let area = document.querySelector("textarea");
      // if (area) area.style.height = `${area.scrollHeight}px`;
    }, [edit]);
    return (
      <div>
        {value ? (
          <div>
            {!edit ? (
              <div
                onMouseMove={(e) => {
                  setEdit(true);
                  e.stopPropagation();
                }}
                style={style}
              >
                {component}
              </div>
            ) : (
              <div
                onMouseLeave={(e) => {
                  setEdit(false);
                  e.stopPropagation();
                }}
              >
                {multiline ? (
                  <textarea
                    id={uuid()}
                    onClick={onClick}
                    style={style}
                    type="text"
                    className={classNames(styles.EditText, cssStyle ?? "")}
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    textarea
                    rows={multilineRows}
                    cols={multilineCols}
                  />
                ) : (
                  <input
                    id={uuid()}
                    onClick={onClick}
                    style={style}
                    type="text"
                    className={classNames(styles.EditText, cssStyle ?? "")}
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    size={text.length - 0.5}
                  />
                )}
              </div>
            )}
          </div>
        ) : (
          <div style={style}></div>
        )}
      </div>
    );
  },
  (prev, next) => {
    return prev.value === next.value && prev.component === next.component;
  }
);

const theme = createTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: { main: "#122526" },
  },
  overrides: {
    MuiInputBase: {
      fullWidth: {
        width: "auto !important",
      },
      root: {
        padding: "2.5px 5px !important",
        textAlign: "left",
        borderRadius: "0 !important",
        // border: "0.1px solid gray",
        borderColor: "gray !important",
        // ,
      },
      input: {
        padding: "2.5px 5px !important",
        textAlign: "left",
        width: "79%",
        borderRadius: "0 !important",
      },
    },
  },
});

const alignTheme = createTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: { main: "#122526" },
  },
  overrides: {
    MuiInputBase: {
      fullWidth: {
        width: "auto !important",
      },
      root: {
        padding: "2.5px 5px !important",
        borderRadius: "0 !important",
      },
      input: {
        padding: "2.5px 5px !important",
      },
    },

    PrivateNotchedOutline: {
      root: {
        borderLeft: "none !important",
      },
      "&$focused": {
        borderLeft: 1,
      },
    },
  },
});

const firstAlignTheme = createTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: { main: "#122526" },
  },
  overrides: {
    MuiInputBase: {
      fullWidth: {
        width: "auto !important",
      },
      root: {
        padding: "2.5px 5px !important",
        borderRadius: "5px 0px 0px 5px !important",
      },
      input: {
        padding: "2.5px 5px !important",
      },
    },
  },
});

const lastAlignTheme = createTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: { main: "#122526" },
  },
  overrides: {
    MuiInputBase: {
      fullWidth: {
        width: "auto !important",
      },
      root: {
        padding: "2.5px 5px !important",
        borderRadius: "0px 5px 5px 0px !important",
      },
      input: {
        padding: "2.5px 5px !important",
      },
    },
    MuiOutlinedInput: {
      root: {
        "&$focused": {
          borderLeft: 1,
        },
      },
      input: {
        padding: 10,
      },
    },
    PrivateNotchedOutline: {
      root: {
        borderLeft: "none !important",
      },
    },
  },
});
