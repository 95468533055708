import { createSlice } from "@reduxjs/toolkit";
import initialState from "./initialState";
import userService from "../utils/services/user";
import { getAll as getAllSubsidiaries } from "./subsidiarySlice";

import config from "../utils/config";
import jwt from "jsonwebtoken";

export const userSlice = createSlice({
  name: "user",
  initialState: initialState.user,
  reducers: {
    receiveUser: (state, action) => {
      state.data = action.payload;
      state.loggedIn = true;
    },
    receiveUsers: (state, action) => {
      state.users.splice(0, state.users.length);
      state.users = action.payload;
    },
    receiveLogout: (state) => {
      state.data = undefined;
      state.loggedIn = false;
      userService.logout();
    },
  },
});

export const { receiveUser, receiveLogout, receiveUsers } = userSlice.actions;

export const login = (email, password, country, idCompany, dispatch) =>
  new Promise((resolve, reject) => {
    userService
      .login(email, password, country, idCompany)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => reject(err));
  });

export const logout = (dispatch) => {
  dispatch(receiveLogout());
  return Promise.resolve(true);
};

export const getUserById = (data, token) =>
  userService
    .getUserById(data, token)
    .then((res) => jwt.verify(res, config.jwtSecret));

export const getAllData = async (companyId, user, subsidiaryId, dispatch) => {
  Promise.all([getAllSubsidiaries(companyId, user.token, dispatch)]).catch(
    (err) => console.log(err)
  );
};

export const getUsersByIdCompany = async (
  id,
  page,
  limit,
  token,
  searchText,
  searchField,
  orderBy,
  order
) => {
  return userService.getUsersByIdCompany(
    id,
    page,
    limit,
    token,
    searchText,
    searchField,
    orderBy,
    order
  );
};

export const getInternalUsersByIdCompany = async (id, token, dispatch) => {
  return await userService
    .getInternalUsersByIdCompany(id, token)
    .then((res) => {
      return res.map((user) => {
        const result = user ? jwt.verify(user, config.jwtSecret) : undefined;
        return result;
      });
    })
    .catch((err) => {
      console.log(err);
      return [];
    });
};

export const remove = async (id, token) => await userService.remove(id, token);

export const update = async (data, token) =>
  await userService.update(jwt.sign(data, config.jwtSecret), token);

export const registerUser = async (data) =>
  await userService.registerUser(jwt.sign(data, config.jwtSecret));

export const success = (user, companyId, subsidiaryId, dispatch) => {
  try {
    var decodedUser = jwt.verify(user, config.jwtSecret);
    getAllData(companyId, decodedUser, subsidiaryId, dispatch);
    dispatch(receiveUser(user));
    return decodedUser;
  } catch (error) {
    return undefined;
  }
};

export const selectUser = (state) => {
  try {
    return state.user.data
      ? jwt.verify(state.user.data, config.jwtSecret)
      : undefined;
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

export const selectUsers = (state) =>
  state.user.users.map((user) =>
    typeof user === "string" ? jwt.verify(user, config.jwtSecret) : user
  );
export const selectLoggedIn = (state) => state.user.loggedIn;

export default userSlice.reducer;
