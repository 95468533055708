/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

//MATERIAL
import {
  withStyles,
  createTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

// CSS

import cssStyles from "./uber.module.css";

//IMPORTS
import { Notification } from "../../../containers/notification";
import { selectUser } from "../../../reducers/userSlice";
import {
  loadingOff,
  loadingOn,
  selectCompanyId,
} from "../../../reducers/uiSlice";
import {
  createShipping,
  getAllShipping,
  removeShipping,
  selectShipping,
  updateShipping,
} from "../../../reducers/shippingSlice";
import shippingConstants from "../../../constants/shipping.constants";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { selectCompany } from "../../../reducers/companySlice";
import InputComponent, { SelectComponent } from "../../inputs";
import jwt from "jsonwebtoken";
import config from "../../../utils/config";
import { Checkbox } from "@material-ui/core";
import { useNavigate } from "react-router-dom";

function UberComponent(props) {
  const companyId = useSelector(selectCompanyId);
  const shippingPolicies = useSelector(selectShipping);
  const [active, setActive] = useState(false);
  const [exists, setExists] = useState(false);
  const [showInputs, setShowInputs] = useState(false);
  const price = 3490;
  const price2 = 3590;
  const price3 = 4000;
  const [id, setId] = useState("");
  const [clientId, setClientId] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [deliveryTime, setDeliveryTime] = useState(30);
  const [fee, setFee] = useState(0);
  const [requested, setRequested] = useState(false);
  const [isTest, setIsTest] = useState(false);
  const [financing, setFinancing] = useState(false);
  const [financingType, setFinancingType] = useState("percentage");
  const [financingValue, setFinancingValue] = useState(0);
  const [description, setDescription] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const company = useSelector(selectCompany);
  const navigate = useNavigate();

  useEffect(() => {
    if (
      user &&
      user.token &&
      user.token !== "" &&
      !requested &&
      company &&
      company._id !== ""
    ) {
      getAllShipping(company._id, user.token, dispatch);
      setRequested(true);
    }
  }, [company, user]);

  useEffect(() => {
    async function getData() {
      dispatch(loadingOn());

      if (shippingPolicies.length > 0) {
        const shipping = shippingPolicies.find(
          (item) => item.useFulfillment && item.fulfillmentId === "Uber"
        );
        // console.log(shipping);
        if (shipping) {
          if (shipping.extraInfo.clientId)
            setClientId(
              jwt.verify(shipping.extraInfo.clientId, config.jwtSecret)
            );
          if (shipping.extraInfo.clientSecret)
            setClientSecret(
              jwt.verify(shipping.extraInfo.clientSecret, config.jwtSecret)
            );
          if (shipping.extraInfo.customerId)
            setCustomerId(
              jwt.verify(shipping.extraInfo.customerId, config.jwtSecret)
            );
          setDeliveryTime(shipping.extraInfo.deliveryTime);
          setFee(shipping.extraInfo.fee);
          setId(shipping._id);
          setIsTest(!!shipping.isTest);
          setActive(shipping.status);
          setExists(true);

          setFinancing(shipping.financing);
          setFinancingType(shipping.financingType);
          setFinancingValue(shipping.financingValue);
          setDescription(shipping.description);
        } else {
          setExists(false);
          setActive(false);
          setId("");
        }
      }
      dispatch(loadingOff());
    }
    getData();
  }, [shippingPolicies]);

  const handleSave = (accepted) => {
    handleShippingSave(accepted);
    // setActive(accepted);
  };

  const handleShippingSave = async (accepted) => {
    dispatch(loadingOn());
    // console.log(id, accepted);
    if (id === "" && accepted) {
      await createShipping(
        {
          name: "Uber",
          type: shippingConstants.TYPES.today.code,
          status: true,
          pickupTimeTable: [],
          useTimeWindow: false,
          useLocationVerification: false,
          shippingTimeTable: [],
          maxDistance: 0,
          price,
          price2,
          price3,
          useFulfillment: true,
          fulfillmentId: "Uber",
          idCompany: companyId,
          extraInfo: {
            clientId: jwt.sign(clientId, config.jwtSecret),
            clientSecret: jwt.sign(clientSecret, config.jwtSecret),
            customerId: jwt.sign(customerId, config.jwtSecret),
            deliveryTime,
            fee,
          },
          isTest,
          description,
          financing,
          financingType,
          financingValue,
        },
        user.token
      );

      getAllShipping(company._id, user.token, dispatch);
      dispatch(loadingOff());
    } else if (id !== "" && !accepted) {
      removeShipping(id, user.token, dispatch)
        .then((res) => {
          enqueueSnackbar(
            Notification({
              text: "Eliminado con éxito",
              variant: "success",
              withDetails: false,
            })
          );
          dispatch(loadingOff());
          setId("");
        })
        .catch((err) => {
          enqueueSnackbar(
            Notification({
              text: "Error al eliminar",
              variant: "error",
              withDetails: false,
            })
          );
          dispatch(loadingOff());
        });
    }
  };

  const handleShippingUpdate = async (active) => {
    dispatch(loadingOn());
    await updateShipping(
      {
        _id: id,
        status: active,
        isTest,
        extraInfo: {
          clientId: jwt.sign(clientId, config.jwtSecret),
          clientSecret: jwt.sign(clientSecret, config.jwtSecret),
          customerId: jwt.sign(customerId, config.jwtSecret),
          deliveryTime,
          fee,
        },
        financing,
        financingType,
        financingValue,
        description,
      },
      user.token
    ).catch((err) => {
      console.log(err);
      enqueueSnackbar(
        Notification({
          text: "Ocurrió un error al intentar actualizar.",
          variant: "error",
          withDetails: false,
        })
      );
      dispatch(loadingOff());
    });

    enqueueSnackbar(
      Notification({
        text: "Actualizado con éxito!",
        variant: "success",
        withDetails: false,
      })
    );
    getAllShipping(company._id, user.token, dispatch);
    dispatch(loadingOff());
  };
  const handleClose = () => {
    navigate(-1);
  };

  return (
    <MuiThemeProvider theme={theme}>
      <div className={cssStyles.parentContainer}>
        <button className={cssStyles.backButton} onClick={() => handleClose()}>
          <span>
            <ArrowBackIcon />
          </span>
          Regresar
        </button>
        <div className={cssStyles.infoContainer}>
          <div className={cssStyles.titleLogoContainer}>
            <div className={cssStyles.containerLogo}>
              <img
                src="https://dgdvd9d8e5bk8.cloudfront.net/uberdireclogowebp.webp"
                alt="Uber"
                className={cssStyles.imageElement}
              />
            </div>
            <div className={cssStyles.serviceStatusContainer}>
              {!active ? (
                <div className={cssStyles.containerLogo}>
                  <img
                    className="stateOffMargin"
                    src="https://s3.amazonaws.com/lumarketo.cl/clock-steps-home.svg"
                    alt=""
                  />
                  Inactivo
                </div>
              ) : (
                <div className={cssStyles.containerLogo}>
                  <img
                    src="https://s3.amazonaws.com/lumarketo.cl/check-steps-home.svg"
                    alt=""
                  />{" "}
                  Activo
                </div>
              )}
            </div>
          </div>

          <div className={cssStyles.infoItemContainer}>
            <p className={cssStyles.infoItemTitle}>
              Uber te ofrece las siguientes servicios{" "}
            </p>
            <p className={cssStyles.infoItem}>
              La activación del servicio de delivery de Uber toma 60 minutos
            </p>
            <p className={cssStyles.infoItem}>
              Estima los envíos según dimensiones y coberturas.
            </p>
          </div>

          <button
            className={cssStyles.buttonConfig}
            onClick={() => {
              setShowInputs((p) => !p);
            }}
          >
            Configurar
            <span>{!showInputs ? <ExpandMoreIcon /> : <ExpandLessIcon />}</span>
          </button>

          {showInputs && (
            <div className={cssStyles.configSettingsContainer}>
              <p className={cssStyles.configSettingsTitle}>Credenciales</p>

              <p className={cssStyles.configSettingsText}>
                Si usted desea hacer uso de la flota de Uber, debe obtener las
                credenciales en su{" "}
                <a
                  href="https://www.ubereats.com"
                  rel="noreferrer"
                  target="_blank"
                >
                  página
                </a>{" "}
                y darnos acceso a ellas para automatizar sus procesos.
              </p>

              <div className={cssStyles.inputContainer}>
                <label className={cssStyles.labelInput}>Client Id</label>

                <InputComponent
                  disabled={!active && exists}
                  placeholder="Ejemplo: 123456789"
                  type="text"
                  helperText={
                    clientId === "" ? "Recuerde indicar el Client Id" : ""
                  }
                  value={clientId}
                  handler={(e) => {
                    setClientId(e.target.value);
                  }}
                />
              </div>
              <div className={cssStyles.inputContainer}>
                <label className={cssStyles.labelInput}>Client Secret</label>

                <InputComponent
                  disabled={!active && exists}
                  placeholder="Ejemplo: 123456789"
                  type="text"
                  helperText={
                    clientSecret === ""
                      ? "Recuerde indicar el Client Secret"
                      : ""
                  }
                  value={clientSecret}
                  handler={(e) => {
                    setClientSecret(e.target.value);
                  }}
                />
              </div>
              <div className={cssStyles.inputContainer}>
                <label className={cssStyles.labelInput}>Customer Id</label>

                <InputComponent
                  disabled={!active && exists}
                  placeholder="Ejemplo: 123456789"
                  type="text"
                  helperText={
                    customerId === "" ? "Recuerde indicar el Customer Id" : ""
                  }
                  value={customerId}
                  handler={(e) => {
                    setCustomerId(e.target.value);
                  }}
                />
              </div>
              <div className={cssStyles.inputContainer}>
                <label className={cssStyles.labelInput}>Descripción</label>

                <InputComponent
                  disabled={!active && exists}
                  placeholder="Ejemplo: Envío a domicilio en 24hrs"
                  type="text"
                  helperText={
                    description === "" ? "Recuerde indicar la descripción" : ""
                  }
                  value={description}
                  handler={(e) => {
                    setDescription(e.target.value);
                  }}
                />
              </div>
              <div className={cssStyles.inputContainer}>
                <label className={cssStyles.labelInput}>
                  Tiempo de entrega
                </label>

                <InputComponent
                  disabled={!active && exists}
                  placeholder="Ejemplo: 30 min"
                  type="number"
                  value={deliveryTime}
                  handler={(e) => {
                    setDeliveryTime(e.target.value);
                  }}
                />
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12" />
              <div className={cssStyles.inputContainer}>
                <label className={cssStyles.labelInput}>
                  Impuestos designados
                </label>

                <InputComponent
                  disabled={!active && exists}
                  placeholder="Ejemplo: 300"
                  type="text"
                  helperText={
                    fee === ""
                      ? "Recuerde indicar el impuesto asignado por Uber, de lo contrario este impuesto no sera cobrado al cliente"
                      : ""
                  }
                  value={fee}
                  handler={(e) => {
                    const value = Number(e.target.value);
                    if (value > 0) setFee(value);
                  }}
                />
              </div>
              <div className={cssStyles.inputContainer}>
                <label className={cssStyles.labelInput}>
                  Activar modo prueba
                </label>
                <Checkbox
                  disabled={!active && exists}
                  checked={isTest}
                  onChange={(e) => setIsTest(e.target.checked)}
                  value={"isTest"}
                  style={{ color: "#122526" }}
                />
              </div>

              <div className={cssStyles.inputContainer}>
                <label className={cssStyles.labelInput}>
                  Activar financiamiento
                </label>
                <Checkbox
                  checked={financing}
                  onChange={(e) => setFinancing(e.target.checked)}
                  value={"isTest"}
                  style={{ color: "#122526" }}
                />
              </div>

              {financing && (
                <div className={cssStyles.inputContainer}>
                  <label className={cssStyles.labelInput}>
                    Tipo del financiamiento
                  </label>
                  <SelectComponent
                    value={financingType}
                    handler={(value) => {
                      setFinancingType(value);
                    }}
                    array={[
                      {
                        name: "Por porcentaje",
                        value: "percentage",
                      },
                      {
                        name: "Por monto",
                        value: "amount",
                      },
                    ]}
                  />
                </div>
              )}

              {financing && (
                <div className={cssStyles.inputContainer}>
                  <label className={cssStyles.labelInput}>
                    Valor del financiamiento
                  </label>

                  <InputComponent
                    placeholder="Ejemplo: 12"
                    type="text"
                    value={financingValue}
                    handler={(e) => {
                      setFinancingValue(e.target.value);
                    }}
                  />
                </div>
              )}
            </div>
          )}

          {showInputs && (
            <div className={cssStyles.buttonsContainer}>
              {active && (
                <button
                  className={cssStyles.confirmButton}
                  onClick={() => handleShippingUpdate(active)}
                >
                  Actualizar
                </button>
              )}
              {id !== "" && (
                <button
                  className={cssStyles.cancelButton}
                  onClick={() => handleShippingUpdate(!active)}
                >
                  {active ? "Inactivar" : "Activar"}
                </button>
              )}
              {id === "" && price !== 0 && price2 !== 0 && price3 !== 0 && (
                <button
                  className={cssStyles.confirmButton}
                  onClick={() => handleSave(true)}
                >
                  Aceptar
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </MuiThemeProvider>
  );
}

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    marginTop: "5%",
    [theme.breakpoints.up("xs")]: {
      marginTop: "15%",
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "5%",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "1%",
    },
  },
  centerTitle: {
    textAlign: "center",
  },
  componentDescription: {
    padding: "0 5px",
  },
  componentContainerFab: {
    display: "flex",
    position: "absolute",
    top: "90%",
  },
  componentHeading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  componentSecondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  textField: {
    width: "100%",
    margin: "5px 0 5px 0px",
  },
  bankTextField: {
    width: "100%",
  },
  unknowBankTextField: {
    width: "86%",
  },
  componentAddItem: {
    display: "flex",
    justifyContent: "center",
  },
  bankCard: {
    border: "1px solid rgba(0, 0, 0, 0.12)",
    display: "flex",
    height: 360,
    width: 450,
    marginBottom: 10,
  },
  cover: {
    width: 200,
    backgroundRepeat: "round",
  },
  details: {
    display: "flex",
    flexDirection: "column",
    width: 250,
  },
  content: {
    flex: "1 0 auto",
  },
  deleteIcon: {
    position: "absolute",
    left: "88%",
    top: "-2%",
  },
  subtitleContainer: {
    margin: "auto 0px",
  },
});

const theme = createTheme({
  typography: {
    useNextVariants: true,
    fontFamily: "Roboto, sans-serif !important",
  },
  palette: {
    primary: { main: "#122526" },
  },
  overrides: {
    MuiFormGroup: {
      root: {
        flexDirection: "column !important",
      },
    },
    MuiFormControlLabel: {
      root: {
        marginBottom: "-5%",
        marginLeft: "5%",
      },
    },
    MuiFormLabel: {
      root: {
        color: "#122526 !important",
      },
    },
    MuiTextField: {
      root: {
        margin: "0px !important",
      },
    },
    MuiInput: {
      underline: {
        "&:hover:not($disabled):before": {
          borderBottom: "2px solid #122526",
        },
        "&:after": {
          borderBottom: "2px solid #122526",
        },
        "&:before": {
          // borderBottom: '2px solid #122526'
        },
      },
    },
    MuiFormControl: {
      root: {
        width: 100,
        margin: "0px",
      },
    },
    MuiCardHeader: {
      root: {
        background: "#122526",
      },
      title: {
        color: "white",
      },
    },
  },
});

export default withStyles(styles)(UberComponent);
