import React, { Fragment, useState } from "react";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import routes from "../../constants/routes";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import userConstants from "../../constants/user.constants";
import webConstants from "../../constants/web.constants";
import Icon from "@material-ui/core/Icon";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import SupervisedUserCircleOutlinedIcon from "@material-ui/icons/SupervisedUserCircleOutlined";
import cssStyles from "./styles.module.css";

import { SideBarIcons } from "../../constants/routes";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";

//MATERIAL-UI
import Link from "@material-ui/core/Link";

import styles from "./styles.module.css";

import marketingIcon from "../../assets/img/marketing.png";
// import templateIcon from '../../../assets/img/template.png';
import editPageIcon from "../../assets/img/edit-page.png";
// import collectionsIcon from '../../../assets/img/collections.png';
import shopConfigurationIcon from "../../assets/img/shop-configuration.png";
import ScheduleIcon from "@material-ui/icons/Schedule";
import { useSelector } from "react-redux";
import { selectUser } from "../../reducers/userSlice";
import { selectCompany } from "../../reducers/companySlice";
//import { selectProducts } from "../../reducers/productSlice";
import dateUtils from "../../utils/date";

import classes from "../../containers/app/app.module.css";
//import { getMailChimp } from "../../utils/services/company";
import AlarmIcon from "@material-ui/icons/Alarm";

export default function ModulesList() {
  const todaysDate = dateUtils.formatDate(new Date());

  const [depositOpen, setDepositOpen] = useState(false);
  const [ordersOpen, setOrdersOpen] = useState(false);
  const [logisticOpen, setLogisticOpen] = useState(false);
  const [recordsOpen, setRecordsOpen] = useState(false);
  const [myAccountOpen, setMyAccountOpen] = useState(false);
  const [checkoutOpen, setCheckoutOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  //const [useMailchimp, setUseMailChimp] = useState(false);
  // const [loaded, setLoaded] = useState(false);
  const user = useSelector(selectUser);
  const company = useSelector(selectCompany);
  //const products = useSelector(selectProducts);

  const location = useLocation();
  const navigate = useNavigate();

  /*   useEffect(() => {
    async function getMailChimpInfo() {
      const found = await getMailChimp(company._id).catch((err) => {
        console.log(err);
        return null;
      });
      setUseMailChimp(!!found);
      setLoaded(true);
    }
    if (company && company._id && company._id !== "" && !loaded)
      getMailChimpInfo();
  }, [company, loaded]); */
  const goTo = (path, returnPath = false) => {
    if (location.pathname !== path && !returnPath) {
      navigate(path);
    } else return path;
  };

  const getActive = (path) => {
    if (`${location.pathname}${location.search}` === path) {
      return true;
    }
    return false;
  };

  return (
    <List style={{ overflowY: "auto" }} dense>
      {/* // INICIO // */}
      <Link
        component={RouterLink}
        to={goTo(routes.HOME.path, true)}
        style={{
          textDecoration: "none",
        }}
      >
        <ListItem button disabled={company?._id === undefined}>
          <ListItemIcon>
            <Icon>
              <img
                className={cssStyles.tabIcon}
                src={SideBarIcons.Home}
                alt="home"
              />
            </Icon>
          </ListItemIcon>
          <ListItemText
            style={{ textTransform: "capitalize" }}
            primary={
              <h6
                className={
                  getActive(routes.HOME.path)
                    ? `${classes.link} ${classes.activeLable}`
                    : classes.link
                }
              >
                {routes.HOME.title}
              </h6>
            }
          />
        </ListItem>
      </Link>

      {/* // GESTION // */}
      <h6 className={styles.label}>Gestión</h6>
      {/* // USERS // */}
      <Link
        component={RouterLink}
        to={goTo(routes.USERS.path, true)}
        style={{
          textDecoration: "none",
        }}
      >
        <ListItem
          button
          disabled={
            company._id === undefined ||
            (user &&
              user.role &&
              user &&
              user.role &&
              userConstants.ROLES.PICKER === user.role)
          }
        >
          <ListItemIcon>
            <Icon>
              <SupervisedUserCircleOutlinedIcon
                style={{ color: "#fff", width: 16, height: 16 }}
              />
            </Icon>
          </ListItemIcon>
          <ListItemText
            primary={
              <h6
                className={
                  getActive(routes.USERS.path)
                    ? `${classes.link} ${classes.activeLable}`
                    : classes.link
                }
              >
                {routes.USERS.title}
              </h6>
            }
          />
        </ListItem>
      </Link>

      {/* // PRODUCTOS // */}
      <ListItem button onClick={() => setDepositOpen(!depositOpen)}>
        <ListItemIcon>
          <Icon>
            <img
              className={cssStyles.tabIcon}
              src={SideBarIcons.Catalogo}
              alt="productos"
            />
          </Icon>
        </ListItemIcon>
        <ListItemText primary={<h6 className={classes.link}>Productos</h6>} />
        {depositOpen ? (
          <ExpandLess style={{ color: "#fff" }} />
        ) : (
          <ExpandMore style={{ color: "#fff" }} />
        )}
      </ListItem>
      <Collapse in={depositOpen} timeout="auto" unmountOnExit>
        <MuiThemeProvider theme={subListTheme}>
          <List component="div" disablePadding>
            <Link
              component={RouterLink}
              to={goTo(`${routes.DEPOSIT.path}/crear`, true)}
              style={{
                textDecoration: "none",
              }}
            >
              <ListItem
                button
                className={classes.nested}
                // onClick={() => goTo(routes.ATTRIBUTES.base)}
                disabled={
                  company._id === undefined ||
                  (user &&
                    user.role &&
                    user &&
                    user.role &&
                    userConstants.ROLES.PICKER === user.role)
                }
              >
                <ListItemText
                  primary={
                    <h6
                      className={
                        getActive(`${routes.DEPOSIT.path}/crear`)
                          ? `${classes.link} ${classes.activeLable}`
                          : classes.link
                      }
                    >
                      Añadir producto
                    </h6>
                  }
                />
              </ListItem>
            </Link>
            <Link
              component={RouterLink}
              to={goTo(routes.ADDITIONAL.base, true)}
              style={{
                textDecoration: "none",
              }}
            >
              <ListItem
                button
                className={classes.nested}
                // onClick={() => goTo(routes.ATTRIBUTES.base)}
                disabled={
                  company._id === undefined ||
                  (user &&
                    user.role &&
                    user &&
                    user.role &&
                    userConstants.ROLES.PICKER === user.role)
                }
              >
                <ListItemText
                  primary={
                    <h6
                      className={
                        getActive(routes.ADDITIONAL.base)
                          ? `${classes.link} ${classes.activeLable}`
                          : classes.link
                      }
                    >
                      {routes.ADDITIONAL.title}
                    </h6>
                  }
                />
              </ListItem>
            </Link>
            <Link
              component={RouterLink}
              to={goTo(routes.CATEGORIES.path, true)}
              style={{
                textDecoration: "none",
              }}
            >
              <ListItem
                button
                className={classes.nested}
                // onClick={() => goTo(routes.CATEGORIES.path)}
                disabled={
                  company._id === undefined ||
                  (user &&
                    user.role &&
                    userConstants.ROLES.PICKER === user.role)
                }
              >
                <ListItemText
                  primary={
                    <h6
                      className={
                        getActive(routes.CATEGORIES.path)
                          ? `${classes.link} ${classes.activeLable}`
                          : classes.link
                      }
                    >
                      {routes.CATEGORIES.title}
                    </h6>
                  }
                />
              </ListItem>
            </Link>
            {/* INVENTARIO */}
            {/*  <Link
              component={RouterLink}
              to={goTo(routes.INVENTARY.path, true)}
              style={{
                textDecoration: "none",
              }}
            >
              <ListItem
                button
                className={classes.nested}
                // onClick={() => goTo(routes.INVENTARY.path)}
                disabled={
                  company._id === undefined ||
                  (user &&
                    user.role &&
                    userConstants.ROLES.PICKER === user.role)
                }
              >
                <ListItemText
                  primary={
                    <h6
                      className={
                        getActive(routes.INVENTARY.path)
                          ? `${classes.link} ${classes.activeLable}`
                          : classes.link
                      }
                    >
                      {routes.INVENTARY.title}
                    </h6>
                  }
                />
              </ListItem>
            </Link> */}
            {/* PRECIO */}
            {/* <Link
              component={RouterLink}
              to={goTo(routes.PRICES.path, true)}
              style={{
                textDecoration: "none",
              }}
            >
              <ListItem
                button
                className={classes.nested}
                // onClick={() => goTo(routes.PRICES.path)}
                disabled={
                  company._id === undefined ||
                  (user &&
                    user.role &&
                    userConstants.ROLES.PICKER === user.role)
                }
              >
                <ListItemText
                  primary={
                    <h6
                      className={
                        getActive(routes.PRICES.path)
                          ? `${classes.link} ${classes.activeLable}`
                          : classes.link
                      }
                    >
                      {routes.PRICES.title}
                    </h6>
                  }
                />
              </ListItem>
            </Link> */}
            <Link
              component={RouterLink}
              to={goTo(routes.DEPOSIT.path, true)}
              style={{
                textDecoration: "none",
              }}
            >
              <ListItem
                button
                className={classes.nested}
                // onClick={() => goTo(routes.DEPOSIT.path)}
                disabled={
                  company._id === undefined ||
                  (user &&
                    user.role &&
                    userConstants.ROLES.PICKER === user.role)
                }
              >
                <ListItemText
                  primary={
                    <h6
                      className={
                        getActive(routes.DEPOSIT.path)
                          ? `${classes.link} ${classes.activeLable}`
                          : classes.link
                      }
                    >
                      {routes.DEPOSIT.title}
                    </h6>
                  }
                />
              </ListItem>
            </Link>
          </List>
        </MuiThemeProvider>
      </Collapse>

      {/* // PEDIDOS // */}
      <ListItem button onClick={() => setOrdersOpen(!ordersOpen)}>
        <ListItemIcon>
          <Icon>
            <img
              className={cssStyles.tabIcon}
              src={SideBarIcons.Ordenes}
              alt="pedidos"
              width={16}
              height={13}
            />
          </Icon>
        </ListItemIcon>
        <ListItemText primary={<h6 className={classes.link}>Pedidos</h6>} />
        {ordersOpen ? (
          <ExpandLess style={{ color: "#fff" }} />
        ) : (
          <ExpandMore style={{ color: "#fff" }} />
        )}
      </ListItem>
      <Collapse in={ordersOpen} timeout="auto" unmountOnExit>
        <MuiThemeProvider theme={subListTheme}>
          <List component="div" disablePadding>
            <Link
              component={RouterLink}
              to={goTo(`${routes.ORDERS.path}?status=none&date=custom`, true)}
              style={{
                textDecoration: "none",
              }}
            >
              <ListItem
                className={classes.nested}
                button
                // onClick={() => goTo(routes.ORDERS.path)}
                disabled={
                  user && user.role && userConstants.ROLES.PICKER === user.role
                }
              >
                <ListItemText
                  primary={
                    <h6
                      className={
                        getActive(
                          `${routes.ORDERS.path}?status=none&date=custom`
                        )
                          ? `${classes.link} ${classes.activeLable}`
                          : classes.link
                      }
                    >
                      {routes.ORDERS.title}
                    </h6>
                  }
                />
              </ListItem>
            </Link>
            {/* //NUEVOS// */}
            {/*     <Link
              component={RouterLink}
              to={goTo(`${routes.ORDERS.path}?status=pending&date=today`, true)}
              style={{
                textDecoration: "none",
              }}
            >
              <ListItem
                className={classes.nested}
                button
                // onClick={() => goTo(routes.ORDERS.path)}
                disabled={
                  user && user.role && userConstants.ROLES.PICKER === user.role
                }
              >
                <ListItemText
                  primary={
                    <h6
                      className={
                        getActive(
                          `${routes.ORDERS.path}?status=pending&date=today`
                        )
                          ? `${classes.link} ${classes.activeLable}`
                          : classes.link
                      }
                    >
                      Nuevos
                    </h6>
                  }
                />
              </ListItem>
            </Link> */}
            <Link
              component={RouterLink}
              to={goTo(
                `${routes.ORDERS.path}?status=pending&date=today&isManualOrder=true`,
                true
              )}
              style={{
                textDecoration: "none",
              }}
            >
              <ListItem
                className={classes.nested}
                button
                // onClick={() => goTo(routes.ORDERS.path)}
                disabled={
                  user && user.role && userConstants.ROLES.PICKER === user.role
                }
              >
                <ListItemText
                  primary={
                    <h6
                      className={
                        getActive(
                          `${routes.ORDERS.path}?status=pending&date=today&isManualOrder=true`
                        )
                          ? `${classes.link} ${classes.activeLable}`
                          : classes.link
                      }
                    >
                      Manuales
                    </h6>
                  }
                />
              </ListItem>
            </Link>
            <Link
              component={RouterLink}
              to={goTo(`${routes.ORDERS.path}?useScheduling=true`, true)}
              style={{
                textDecoration: "none",
              }}
            >
              <ListItem
                className={classes.nested}
                button
                // onClick={() => goTo(routes.ORDERS.path)}
                disabled={
                  user && user.role && userConstants.ROLES.PICKER === user.role
                }
              >
                <ListItemText
                  primary={
                    <h6
                      className={
                        getActive(`${routes.ORDERS.path}?useScheduling=true`)
                          ? `${classes.link} ${classes.activeLable}`
                          : classes.link
                      }
                    >
                      Programados
                    </h6>
                  }
                />
              </ListItem>
            </Link>
          </List>
        </MuiThemeProvider>
      </Collapse>
      {/* // Reseñas de pedidos// */}
      <Link
        component={RouterLink}
        to={goTo(routes.REVIEWS.path, true)}
        style={{
          textDecoration: "none",
        }}
      >
        <ListItem
          button
          disabled={
            company._id === undefined ||
            (user &&
              user.role &&
              user &&
              user.role &&
              userConstants.ROLES.PICKER === user.role)
          }
        >
          <ListItemIcon>
            <Icon>
              <img
                className={cssStyles.tabIcon}
                src={SideBarIcons.Ordenes}
                alt="pedidos"
                width={16}
                height={13}
              />
            </Icon>
          </ListItemIcon>
          <ListItemText
            primary={
              <h6
                className={
                  getActive(routes.REVIEWS.path)
                    ? `${classes.link} ${classes.activeLable}`
                    : classes.link
                }
              >
                Reseñas de pedidos {/* {routes.REVIEWS.title} */}
              </h6>
            }
          />
        </ListItem>
      </Link>

      <Link
        component={RouterLink}
        to={goTo(routes.SUBSIDIARIES.path, true)}
        style={{
          textDecoration: "none",
        }}
      >
        <ListItem button onClick={() => goTo(routes.SUBSIDIARIES.path)}>
          <ListItemIcon>
            <Icon>
              <ScheduleIcon style={{ color: "#fff", width: 16 }} />
            </Icon>
          </ListItemIcon>
          <ListItemText
            primary={
              <h6
                className={
                  getActive(routes.SUBSIDIARIES.path)
                    ? `${classes.link} ${classes.activeLable}`
                    : classes.link
                }
              >
                {routes.SUBSIDIARIES.title}
              </h6>
            }
          />
        </ListItem>
      </Link>

      {/* // RESERVAS // */}
      <Link
        component={RouterLink}
        to={goTo(routes.BOOKINGS.path, true)}
        style={{
          textDecoration: "none",
        }}
      >
        <ListItem button onClick={() => goTo(routes.BOOKINGS.path)}>
          <ListItemIcon>
            <Icon>
              <AlarmIcon style={{ color: "#fff", width: 16 }} />
            </Icon>
          </ListItemIcon>
          <ListItemText
            primary={
              <h6
                className={
                  getActive(routes.BOOKINGS.path)
                    ? `${classes.link} ${classes.activeLable}`
                    : classes.link
                }
              >
                {routes.BOOKINGS.title}
              </h6>
            }
          />
        </ListItem>
      </Link>
      {/*  Reportes */}
      <Link
        component={RouterLink}
        to={goTo(
          routes.SALES.path + `?startDate=${todaysDate}&endDate=${todaysDate}`,
          true
        )}
        style={{
          textDecoration: "none",
        }}
      >
        <ListItem
          button
          disabled={
            company?._id === undefined ||
            (user?.role && userConstants?.ROLES?.ADMIN !== user?.role)
          }
        >
          <ListItemIcon>
            <Icon>
              <img
                className={cssStyles.tabIcon}
                src={SideBarIcons.Registros}
                alt="home"
              />
            </Icon>
          </ListItemIcon>
          <ListItemText
            style={{ textTransform: "capitalize" }}
            primary={
              <h6
                className={
                  getActive(routes.SALES.path)
                    ? `${classes.link} ${classes.activeLable}`
                    : classes.link
                }
              >
                Reporte de Ventas
              </h6>
            }
          />
        </ListItem>
      </Link>
      {/*  INCIDENCIAS*/}
      <Link
        component={RouterLink}
        to={goTo(routes.INCIDENTS.path, true)}
        style={{
          textDecoration: "none",
        }}
      >
        <ListItem button disabled={company?._id === undefined}>
          <ListItemIcon>
            <Icon>
              <img
                className={cssStyles.tabIcon}
                src={SideBarIcons.Registros}
                alt="home"
              />
            </Icon>
          </ListItemIcon>
          <ListItemText
            style={{ textTransform: "capitalize" }}
            primary={
              <h6
                className={
                  getActive(routes.INCIDENTS.path)
                    ? `${classes.link} ${classes.activeLable}`
                    : classes.link
                }
              >
                {routes.INCIDENTS.title}
              </h6>
            }
          />
        </ListItem>
      </Link>
      {/* // MENUS // */}
      <ListItem button onClick={() => setMenuOpen(!menuOpen)}>
        <ListItemIcon>
          <Icon>
            <img
              className={cssStyles.tabIcon}
              src={SideBarIcons.Ordenes}
              alt="menus"
              width={16}
              height={13}
            />
          </Icon>
        </ListItemIcon>
        <ListItemText primary={<h6 className={classes.link}>Menus</h6>} />
        {menuOpen ? (
          <ExpandLess style={{ color: "#fff" }} />
        ) : (
          <ExpandMore style={{ color: "#fff" }} />
        )}
      </ListItem>

      <Collapse in={menuOpen} timeout="auto" unmountOnExit>
        <MuiThemeProvider theme={subListTheme}>
          <List component="div" disablePadding>
            <Link
              component={RouterLink}
              to={goTo(`${routes.MODULE_WEB.path}`, true)}
              style={{
                textDecoration: "none",
              }}
            >
              <ListItem
                className={classes.nested}
                button
                // onClick={() => goTo(routes.ORDERS.path)}
                disabled={
                  user && user.role && userConstants.ROLES.PICKER === user.role
                }
              >
                <ListItemText
                  primary={
                    <h6
                      className={
                        getActive(`${routes.MODULE_WEB.path}`)
                          ? `${classes.link} ${classes.activeLable}`
                          : classes.link
                      }
                    >
                      Página de inicio
                    </h6>
                  }
                />
              </ListItem>
            </Link>
            <Link
              component={RouterLink}
              to={goTo(`${routes.MODULE_WEB.path}?page=menuqr`, true)}
              style={{
                textDecoration: "none",
              }}
            >
              <ListItem
                className={classes.nested}
                button
                // onClick={() => goTo(routes.ORDERS.path)}
                disabled={
                  user && user.role && userConstants.ROLES.PICKER === user.role
                }
              >
                <ListItemText
                  primary={
                    <h6
                      className={
                        getActive(`${routes.MODULE_WEB.path}?page=menuqr`)
                          ? `${classes.link} ${classes.activeLable}`
                          : classes.link
                      }
                    >
                      Página QR
                    </h6>
                  }
                />
              </ListItem>
            </Link>
            <Link
              component={RouterLink}
              to={goTo(routes.MENU.path, true)}
              style={{
                textDecoration: "none",
              }}
            >
              <ListItem
                className={classes.nested}
                button
                // onClick={() => goTo(routes.ORDERS.path)}
                disabled={
                  user && user.role && userConstants.ROLES.PICKER === user.role
                }
              >
                <ListItemText
                  primary={
                    <h6
                      className={
                        getActive(routes.MENU.path)
                          ? `${classes.link} ${classes.activeLable}`
                          : classes.link
                      }
                    >
                      Ver mis QRs
                    </h6>
                  }
                />
              </ListItem>
            </Link>
            <Link
              component={RouterLink}
              to={goTo(`${routes.QR_SURVEY.path}`, true)}
              style={{
                textDecoration: "none",
              }}
            >
              <ListItem
                className={classes.nested}
                button
                // onClick={() => goTo(routes.ORDERS.path)}
                disabled={
                  user && user.role && userConstants.ROLES.PICKER === user.role
                }
              >
                <ListItemText
                  primary={
                    <h6
                      className={
                        getActive(`${routes.QR_SURVEY.path}`)
                          ? `${classes.link} ${classes.activeLable}`
                          : classes.link
                      }
                    >
                      Encuesta QR
                    </h6>
                  }
                />
              </ListItem>
            </Link>
          </List>
        </MuiThemeProvider>
      </Collapse>

      {/* // Soporte al cliente //  */}
      <h6 className={styles.label}>Soporte al cliente</h6>
      <Link
        component={RouterLink}
        to={goTo(routes.CUSTOMER_SERVICE.path, true)}
        style={{
          textDecoration: "none",
        }}
      >
        <ListItem
          button
          // onClick={() => goTo(routes.CUSTOMER_SERVICE.path)}
          disabled={
            user && user.role && userConstants.ROLES.PICKER === user.role
          }
        >
          <ListItemIcon>
            <Icon>
              <img
                className={cssStyles.tabIcon}
                src={SideBarIcons.Servicioalcliente}
                alt="home"
              />
            </Icon>
          </ListItemIcon>
          <ListItemText
            primary={<h6 className={classes.link}>Tickets de soporte</h6>}
            className={
              getActive(routes.CUSTOMER_SERVICE.path)
                ? `${classes.link} ${classes.activeLable}`
                : classes.link
            }
          />
        </ListItem>
      </Link>
      {/* //INTEGRACIONES //  */}
      <h6 className={styles.label}>INTEGRACIONES</h6>
      <Link
        component={RouterLink}
        to={goTo(routes.INTEGRATIONS_NATIVE.path, true)}
        style={{
          textDecoration: "none",
        }}
      >
        <ListItem
          button
          onClick={() => goTo(routes.INTEGRATIONS_NATIVE.path)}
          disabled={
            company._id === undefined ||
            (user && user.role && userConstants.ROLES.ADMIN !== user.role)
          }
        >
          <ListItemIcon>
            <Icon>
              <img
                className={cssStyles.tabIcon}
                src={SideBarIcons.integrations}
                alt="home"
              />
            </Icon>
          </ListItemIcon>
          <ListItemText
            primary={
              <h6
                className={
                  getActive(routes.INTEGRATIONS_NATIVE.path)
                    ? `${classes.link} ${classes.activeLable}`
                    : classes.link
                }
              >
                {routes.INTEGRATIONS_NATIVE.title}
              </h6>
            }
          />
        </ListItem>
      </Link>
      {/* // Marketing //  */}
      <h6 className={styles.label}>Marketing</h6>
      <Link
        component={RouterLink}
        to={goTo(routes.COUPON_MARKETING.path, true)}
        style={{
          textDecoration: "none",
        }}
      >
        <ListItem
          button
          disabled={
            company._id === undefined ||
            (user &&
              user.role &&
              user &&
              user.role &&
              userConstants.ROLES.PICKER === user.role)
          }
        >
          <ListItemIcon>
            <Icon>
              <img
                className={cssStyles.tabIcon}
                src={SideBarIcons.coupon}
                alt="home"
              />
            </Icon>
          </ListItemIcon>
          <ListItemText
            primary={
              <h6
                className={
                  getActive(routes.COUPON_MARKETING.path)
                    ? `${classes.link} ${classes.activeLable}`
                    : classes.link
                }
              >
                {routes.COUPON_MARKETING.title}
              </h6>
            }
          />
        </ListItem>
      </Link>
      <Link
        component={RouterLink}
        to={goTo(routes.EMAIL_MARKETING.path, true)}
        style={{
          textDecoration: "none",
        }}
      >
        <ListItem
          button
          disabled={
            company._id === undefined ||
            (user &&
              user.role &&
              user &&
              user.role &&
              userConstants.ROLES.PICKER === user.role)
          }
        >
          <ListItemIcon>
            <Icon>
              <MailOutlineIcon
                style={{
                  color: "#fff",
                  width: 16,
                  height: "auto",
                }}
              />
            </Icon>
          </ListItemIcon>
          <ListItemText
            primary={
              <h6
                className={
                  getActive(routes.EMAIL_MARKETING.path)
                    ? `${classes.link} ${classes.activeLable}`
                    : classes.link
                }
              >
                {routes.EMAIL_MARKETING.title}
              </h6>
            }
          />
        </ListItem>
      </Link>

      <Link
        component={RouterLink}
        to={goTo(routes.DISCOUNT_MARKETING.path, true)}
        style={{
          textDecoration: "none",
        }}
      >
        <ListItem
          button
          // onClick={() => goTo(routes.DISCOUNT_MARKETING.path)}
          disabled={
            company._id === undefined ||
            (user &&
              user.role &&
              user &&
              user.role &&
              userConstants.ROLES.PICKER === user.role)
          }
        >
          <ListItemIcon>
            <Icon>
              <img
                className={cssStyles.tabIcon}
                src={marketingIcon}
                alt="home"
              />
            </Icon>
          </ListItemIcon>
          <ListItemText
            primary={
              <h6
                className={
                  getActive(routes.DISCOUNT_MARKETING.path)
                    ? `${classes.link} ${classes.activeLable}`
                    : classes.link
                }
              >
                {routes.DISCOUNT_MARKETING.title}
              </h6>
            }
          />
        </ListItem>
      </Link>

      <Link
        component={RouterLink}
        to={goTo(routes.MAILCHIMP_MARKETING.path, true)}
        style={{
          textDecoration: "none",
        }}
      >
        <ListItem
          button
          disabled={
            company._id === undefined ||
            (user &&
              user.role &&
              user &&
              user.role &&
              userConstants.ROLES.PICKER === user.role)
          }
        >
          <ListItemIcon>
            <Icon>
              <MailOutlineIcon
                style={{
                  color: "#fff",
                  width: 16,
                  height: "auto",
                }}
              />
            </Icon>
          </ListItemIcon>
          <ListItemText
            primary={
              <h6
                className={
                  getActive(routes.MAILCHIMP_MARKETING.path)
                    ? `${classes.link} ${classes.activeLable}`
                    : classes.link
                }
              >
                {routes.MAILCHIMP_MARKETING.title}
              </h6>
            }
          />
        </ListItem>
      </Link>

      {/* // Canales de ventas // */}
      {/*      <h6 className={styles.label}>Canales de ventas</h6>
      <Link
        component={RouterLink}
        to={goTo(routes.FACEBOOK_SHOPPING.base, true)}
        style={{
          textDecoration: "none",
        }}
      >
        <ListItem
          button
          onClick={() => goTo(routes.FACEBOOK_SHOPPING.base)}
          disabled={
            company._id === undefined ||
            (user &&
              user.role &&
              user &&
              user.role &&
              userConstants.ROLES.PICKER === user.role)
          }
        >
          <ListItemIcon>
            <Icon>
              <img
                className={cssStyles.tabIcon}
                src={SideBarIcons.FacebookShopping}
                alt="home"
              />
            </Icon>
          </ListItemIcon>
          <ListItemText
            primary={
              <h6
                className={
                  getActive(routes.FACEBOOK_SHOPPING.base)
                    ? `${classes.link} ${classes.activeLable}`
                    : classes.link
                }
              >
                {routes.FACEBOOK_SHOPPING.title}
              </h6>
            }
          />
        </ListItem>
      </Link>
      <Link
        component={RouterLink}
        to={goTo(routes.INSTAGRAM_SHOPPING.base, true)}
        style={{
          textDecoration: "none",
        }}
      >
        <ListItem
          button
          onClick={() => goTo(routes.INSTAGRAM_SHOPPING.base)}
          disabled={
            company._id === undefined ||
            (user &&
              user.role &&
              user &&
              user.role &&
              userConstants.ROLES.PICKER === user.role)
          }
        >
          <ListItemIcon>
            <Icon>
              <img
                className={cssStyles.tabIcon}
                src={SideBarIcons.InstagramShopping}
                alt="home"
              />
            </Icon>
          </ListItemIcon>
          <ListItemText
            primary={
              <h6
                className={
                  getActive(routes.INSTAGRAM_SHOPPING.base)
                    ? `${classes.link} ${classes.activeLable}`
                    : classes.link
                }
              >
                {routes.INSTAGRAM_SHOPPING.title}
              </h6>
            }
          />
        </ListItem>
      </Link>
      <Link
        component={RouterLink}
        to={goTo(routes.GOOGLE_SHOPPING.path, true)}
        style={{
          textDecoration: "none",
        }}
      >
        <ListItem
          button
          onClick={() => goTo(routes.GOOGLE_SHOPPING.path)}
          disabled={
            company._id === undefined ||
            (user &&
              user.role &&
              user &&
              user.role &&
              userConstants.ROLES.PICKER === user.role)
          }
        >
          <ListItemIcon>
            <Icon>
              <img
                className={cssStyles.tabIcon}
                src={SideBarIcons.GoogleShopping}
                alt="home"
              />
            </Icon>
          </ListItemIcon>
          <ListItemText
            primary={
              <h6
                className={
                  getActive(routes.GOOGLE_SHOPPING.path)
                    ? `${classes.link} ${classes.activeLable}`
                    : classes.link
                }
              >
                {routes.GOOGLE_SHOPPING.title}
              </h6>
            }
          />
        </ListItem>
      </Link> */}

      {/* // Personalizacion de tienda //  */}
      <h6 className={styles.label}>Personalización de tienda</h6>
      {/* <Link
        component={RouterLink}
        to={goTo(
          `${routes.WEB.path}?c=${webConstants.routes.COLLECTIONS}`,
          true
        )}
        style={{
          textDecoration: "none",
        }}
      >
        <ListItem

          button
          disabled={
            company._id === undefined ||
            (user && user.role && (user && user.role && userConstants.ROLES.PICKER === user.role))
          }
        >
          <ListItemIcon>
            <Icon>
              <img
                className={cssStyles.tabIcon}
                src={collectionsIcon}
                alt="home"
              />
            </Icon>
          </ListItemIcon>
          <ListItemText
            primary={<h6>Colecciones</h6>}
            className={
              getActive(
                `${routes.WEB.path}?c=${webConstants.routes.COLLECTIONS}`
              )
                ? `${classes.link} ${classes.activeLable}`
                : classes.link
            }
          />
        </ListItem>
      </Link> */}

      <Link
        component={RouterLink}
        to={goTo(`${routes.WEB.path}?c=${webConstants.routes.SETTINGS}`, true)}
        style={{
          textDecoration: "none",
        }}
      >
        <ListItem
          button
          disabled={
            company._id === undefined ||
            (user &&
              user.role &&
              user &&
              user.role &&
              userConstants.ROLES.PICKER === user.role)
          }
        >
          <ListItemIcon>
            <Icon>
              <img
                className={cssStyles.tabIcon}
                src={shopConfigurationIcon}
                alt="home"
              />
            </Icon>
          </ListItemIcon>
          <ListItemText
            primary={
              <h6
                className={
                  getActive(
                    `${routes.WEB.path}?c=${webConstants.routes.SETTINGS}`
                  )
                    ? `${classes.link} ${classes.activeLable}`
                    : classes.link
                }
              >
                Configuraciones
              </h6>
            }
          />
        </ListItem>
      </Link>
      <Link
        component={RouterLink}
        to={goTo(
          company.UseHome
            ? routes.MODULE_WEB.path
            : `${routes.MODULE_WEB.path}?page=pedir`,
          true
        )}
        style={{
          textDecoration: "none",
        }}
      >
        <ListItem
          button
          disabled={
            company._id === undefined ||
            (user &&
              user.role &&
              user &&
              user.role &&
              userConstants.ROLES.PICKER === user.role)
          }
        >
          <ListItemIcon>
            <Icon>
              <img
                className={cssStyles.tabIcon}
                src={editPageIcon}
                alt="home"
              />
            </Icon>
          </ListItemIcon>
          <ListItemText
            primary={
              <h6
                className={
                  getActive(
                    company.UseHome
                      ? routes.MODULE_WEB.path
                      : `${routes.MODULE_WEB.path}?page=pedir`
                  )
                    ? `${classes.link} ${classes.activeLable}`
                    : classes.link
                }
              >
                Editar página
              </h6>
            }
          />
        </ListItem>
      </Link>
      {/* <Link
        component={RouterLink}
        to={goTo(
          `${routes.WEB.path}?c=${webConstants.routes.TEMPLATES}`,
          true
        )}
        style={{
          textDecoration: "none",
        }}
      >

        <ListItem
          button
          disabled={
            company._id === undefined ||
            (user && user.role && (user && user.role && userConstants.ROLES.PICKER === user.role))
          }
        >
          <ListItemIcon>
            <Icon>
              <img
                className={cssStyles.tabIcon}
                src={templateIcon}
                alt="home"
              />
            </Icon>
          </ListItemIcon>
          <ListItemText
            primary={<h6>Plantillas</h6>}
            className={
              getActive(
                `${routes.WEB.path}?c=${webConstants.routes.TEMPLATES}`
              )
                ? `${classes.link} ${classes.activeLable}`
                : classes.link
            }
          />
        </ListItem>
      </Link> */}

      {/* // Configuraciones // */}
      <h6 className={styles.label}>Configuración</h6>
      <ListItem button onClick={() => setCheckoutOpen(!checkoutOpen)}>
        <ListItemIcon>
          <ListItemIcon>
            <Icon>
              <img
                className={cssStyles.tabIcon}
                src={SideBarIcons.Checkout}
                alt="home"
              />
            </Icon>
          </ListItemIcon>
        </ListItemIcon>
        <ListItemText primary={<h6 className={classes.link}>Checkout</h6>} />
        {checkoutOpen ? (
          <ExpandLess style={{ color: "#fff" }} />
        ) : (
          <ExpandMore style={{ color: "#fff" }} />
        )}
      </ListItem>
      <Collapse in={checkoutOpen} timeout="auto" unmountOnExit>
        <ListItem
          button
          onClick={() => setLogisticOpen(!logisticOpen)}
          className={classes.nested}
        >
          <ListItemText primary={<h6 className={classes.link}>Envíos</h6>} />
          {logisticOpen ? (
            <ExpandLess style={{ color: "#fff" }} />
          ) : (
            <ExpandMore style={{ color: "#fff" }} />
          )}
        </ListItem>
        <Collapse in={logisticOpen} timeout="auto" unmountOnExit>
          <MuiThemeProvider theme={subListTheme}>
            <List component="div" disablePadding>
              <Link
                component={RouterLink}
                to={goTo(routes.SHIPPING_PREFERENCE.path, true)}
                style={{
                  textDecoration: "none",
                }}
              >
                <ListItem
                  className={classes.subListNested}
                  button
                  onClick={() => goTo(routes.SHIPPING_PREFERENCE.path)}
                  disabled={
                    company._id === undefined ||
                    (user &&
                      user.role &&
                      userConstants.ROLES.ADMIN !== user.role)
                  }
                >
                  <ListItemText
                    primary={
                      <h6
                        className={
                          getActive(routes.SHIPPING_PREFERENCE.path)
                            ? `${classes.link} ${classes.activeLable}`
                            : classes.link
                        }
                      >
                        {routes.SHIPPING_PREFERENCE.title}
                      </h6>
                    }
                  />
                </ListItem>
              </Link>
            </List>
          </MuiThemeProvider>
        </Collapse>
        {user && user.role && userConstants.ROLES.ADMIN === user.role && (
          <Link
            component={RouterLink}
            to={goTo(routes.BSALE_SETTINGS.path, true)}
            style={{
              textDecoration: "none",
            }}
          >
            <ListItem
              className={classes.nested}
              button
              onClick={() => goTo(routes.BSALE_SETTINGS.path)}
              disabled={
                company._id === undefined ||
                (user && user.role && userConstants.ROLES.ADMIN !== user.role)
              }
            >
              <ListItemText
                primary={
                  <h6
                    className={
                      getActive(routes.BSALE_SETTINGS.path)
                        ? `${classes.link} ${classes.activeLable}`
                        : classes.link
                    }
                  >
                    {routes.BSALE_SETTINGS.title}
                  </h6>
                }
              />
            </ListItem>
          </Link>
        )}
      </Collapse>
      <ListItem button onClick={() => setMyAccountOpen(!myAccountOpen)}>
        <ListItemIcon>
          <ListItemIcon>
            <Icon>
              <AccountCircleOutlinedIcon
                style={{ color: "#fff", width: 16, height: 16 }}
              />
            </Icon>
          </ListItemIcon>
        </ListItemIcon>
        <ListItemText primary={<h6 className={classes.link}>Mi cuenta </h6>} />
        {myAccountOpen ? (
          <ExpandLess style={{ color: "#fff" }} />
        ) : (
          <ExpandMore style={{ color: "#fff" }} />
        )}
      </ListItem>
      <Collapse in={myAccountOpen} timeout="auto" unmountOnExit>
        <Link
          component={RouterLink}
          to={goTo(routes.COMPANY_PAYMENTS_SETTINGS.path, true)}
          style={{
            textDecoration: "none",
          }}
        >
          <ListItem
            className={classes.nested}
            button
            onClick={() => goTo(routes.COMPANY_PAYMENTS_SETTINGS.path)}
            disabled={
              company._id === undefined ||
              (user && user.role && userConstants.ROLES.ADMIN !== user.role)
            }
          >
            <ListItemText
              primary={
                <h6
                  className={
                    getActive(routes.COMPANY_PAYMENTS_SETTINGS.path)
                      ? `${classes.link} ${classes.activeLable}`
                      : classes.link
                  }
                >
                  {routes.COMPANY_PAYMENTS_SETTINGS.title}
                </h6>
              }
            />
          </ListItem>
        </Link>
        <Link
          component={RouterLink}
          to={goTo(routes.GENERAL_SETTINGS.path, true)}
          style={{
            textDecoration: "none",
          }}
        >
          <ListItem
            className={classes.nested}
            button
            onClick={() => goTo(routes.GENERAL_SETTINGS.path)}
            disabled={
              company._id === undefined ||
              (user && user.role && userConstants.ROLES.ADMIN !== user.role)
            }
          >
            <ListItemText
              primary={
                <h6
                  className={
                    getActive(routes.GENERAL_SETTINGS.path)
                      ? `${classes.link} ${classes.activeLable}`
                      : classes.link
                  }
                >
                  {routes.GENERAL_SETTINGS.title}
                </h6>
              }
            />
          </ListItem>
        </Link>
        {/* <Link
          component={RouterLink}
          to={goTo(routes.PLANS.path, true)}
          style={{
            textDecoration: "none",
          }}
        >
          <ListItem
            button
            onClick={() => goTo(routes.PLANS.path)}
            disabled={
              company._id === undefined ||
              (user && user.role && userConstants.ROLES.ADMIN !== user.role)
            }
            className={classes.nested}
          >
            <ListItemText
              primary={
                <h6
                  className={
                    getActive(routes.PLANS.path)
                      ? `${classes.link} ${classes.activeLable}`
                      : classes.link
                  }
                >
                  {routes.PLANS.title}
                </h6>
              }
            />
          </ListItem>
        </Link> */}

        <Link
          component={RouterLink}
          to={goTo(routes.PICKERS_SETTINGS.path, true)}
          style={{
            textDecoration: "none",
          }}
        >
          <ListItem
            button
            className={classes.nested}
            disabled={
              company._id === undefined ||
              (user && user.role && userConstants.ROLES.ADMIN !== user.role)
            }
          >
            <ListItemText
              primary={
                <h6
                  className={
                    getActive(routes.PICKERS_SETTINGS.path)
                      ? `${classes.link} ${classes.activeLable}`
                      : classes.link
                  }
                >
                  {routes.PICKERS_SETTINGS.title}
                </h6>
              }
            />
          </ListItem>
        </Link>

        <ListItem
          button
          onClick={() => setRecordsOpen(!recordsOpen)}
          className={classes.nested}
        >
          <ListItemText
            primary={<h6 className={classes.link}>Registros </h6>}
          />
          {recordsOpen ? (
            <ExpandLess style={{ color: "#fff" }} />
          ) : (
            <ExpandMore style={{ color: "#fff" }} />
          )}
        </ListItem>
        <Collapse in={recordsOpen} timeout="auto" unmountOnExit>
          <MuiThemeProvider theme={subListTheme}>
            <List component="div" disablePadding>
              <Link
                component={RouterLink}
                to={goTo(routes.DEPOSIT_CHANGES.path, true)}
                style={{
                  textDecoration: "none",
                }}
              >
                <ListItem
                  className={classes.subListNested}
                  button
                  // onClick={() => goTo(routes.DEPOSIT_CHANGES.path)}
                  disabled={
                    company._id === undefined ||
                    (user &&
                      user.role &&
                      userConstants.ROLES.PICKER === user.role)
                  }
                >
                  <ListItemText
                    primary={
                      <h6
                        className={
                          getActive(routes.DEPOSIT_CHANGES.path)
                            ? `${classes.link} ${classes.activeLable}`
                            : classes.link
                        }
                      >
                        {routes.DEPOSIT_CHANGES.title}
                      </h6>
                    }
                  />
                </ListItem>
              </Link>
              <Link
                component={RouterLink}
                to={goTo(routes.SETTINGS_CHANGES.path, true)}
                style={{
                  textDecoration: "none",
                }}
              >
                <ListItem
                  className={classes.subListNested}
                  button
                  // onClick={() => goTo(routes.SETTINGS_CHANGES.path)}
                  disabled={
                    company._id === undefined ||
                    (user &&
                      user.role &&
                      userConstants.ROLES.PICKER === user.role)
                  }
                >
                  <ListItemText
                    primary={
                      <h6
                        className={
                          getActive(routes.SETTINGS_CHANGES.path)
                            ? `${classes.link} ${classes.activeLable}`
                            : classes.link
                        }
                      >
                        {routes.SETTINGS_CHANGES.title}
                      </h6>
                    }
                  />
                </ListItem>
              </Link>

              <Link
                component={RouterLink}
                to={goTo(routes.ORDERS_CHANGES.path, true)}
                style={{
                  textDecoration: "none",
                }}
              >
                <ListItem
                  className={classes.subListNested}
                  button
                  // onClick={() => goTo(routes.ORDERS_CHANGES.path)}
                  disabled={
                    company._id === undefined ||
                    (user &&
                      user.role &&
                      userConstants.ROLES.PICKER === user.role)
                  }
                >
                  <ListItemText
                    primary={
                      <h6
                        className={
                          getActive(routes.ORDERS_CHANGES.path)
                            ? `${classes.link} ${classes.activeLable}`
                            : classes.link
                        }
                      >
                        {routes.ORDERS_CHANGES.title}
                      </h6>
                    }
                  />
                </ListItem>
              </Link>
            </List>
          </MuiThemeProvider>
        </Collapse>
      </Collapse>

      {user && user.role && userConstants.ROLES.ADMIN === user.role && (
        <Fragment>
          {company.UseEmailConfig && (
            <Link
              component={RouterLink}
              to={goTo(routes.EMAIL_SETTINGS.path, true)}
              style={{
                textDecoration: "none",
              }}
            >
              <ListItem
                button
                onClick={() => goTo(routes.EMAIL_SETTINGS.path)}
                disabled={
                  company._id === undefined ||
                  (user && user.role && userConstants.ROLES.ADMIN !== user.role)
                }
              >
                <ListItemText
                  primary={
                    <h6
                      className={
                        getActive(routes.EMAIL_SETTINGS.path)
                          ? `${classes.link} ${classes.activeLable}`
                          : classes.link
                      }
                    >
                      {routes.EMAIL_SETTINGS.title}
                    </h6>
                  }
                />
              </ListItem>
            </Link>
          )}
        </Fragment>
      )}
    </List>
  );
}

const subListTheme = createTheme({
  typography: {
    useNextVariants: true,
    fontFamily: "Montserrat-Light !important",
  },
  palette: {
    primary: { main: "#122526" },
  },
  overrides: {
    MuiTypography: {
      body1: {
        color: "white",
        fontSize: 12,
        fontFamily: "Montserrat-Light !important",
        fontWeight: 700,
        lineHeight: 1.43,
      },
      body2: {
        color: "white",
        fontSize: 12,
        fontFamily: "Montserrat-Light !important",
        fontWeight: 700,
        lineHeight: 1.43,
      },
    },
    MuiListItem: {
      root: {
        padding: "3px 16px",
        height: 25,
        "&:hover, &:focus": {
          background: "rgba(241,222,250,0.275) !important",
          "&:before": {
            background: "#fff",
            transform: "scale(1)",
          },
        },
        "&$selected": {
          "&.Mui-selected": {
            background: "initial",
            "&:hover, &:focus": {
              background: "rgba(241,222,250,0.275) !important",
              color: "#663399",
              "&:before": {
                background: "#fff !important",
                transform: "scale(1)",
              },
            },
            "&:after": {
              width: "calc(0.5rem + 8px)",
              background: "rgb(138, 75, 175) !important",
              transform: "translateX(0)",
            },
          },
        },
      },
      dense: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
  },
});
