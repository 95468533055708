import config from "../config";
import { get, put, Delete, post } from "../http";
import { create as createHistory } from "./imgHistory";
async function getStatus() {
  const headers = {
    "Content-Type": "application/json",
  };
  return await get(`${config.urlApi}/system/status`, headers);
}

export async function getProductById(id) {
  const headers = {
    "Content-Type": "application/json",
  };

  return await get(`${config.urlApi}/product/getProduct?id=${id}`, headers);
}
export async function getProducByBarCode(code, companyId) {
  const headers = {
    "Content-Type": "application/json",
  };

  return await get(
    `${config.urlApi}/product/getByBarCode?code=${code}&idCompany=${companyId}`,
    headers
  );
}

export async function getProducBySku(sku, companyId) {
  const headers = {
    "Content-Type": "application/json",
  };

  return await get(
    `${config.urlApi}/product/getBySKU?sku=${sku}&idCompany=${companyId}`,
    headers
  );
}

export async function getSkus(skus, companyId) {
  const headers = {
    "Content-Type": "application/json",
  };

  return await post(
    `${config.urlApi}/product/getSKUs?idCompany=${companyId}`,
    headers,
    { skus }
  );
}

export async function getProductByBrand(brand, category, companyId) {
  const headers = {
    "Content-Type": "application/json",
  };

  return await get(
    `${config.urlApi}/product/getProductByBrand?brand=${brand}&category=${category}&companyId=${companyId}`,
    headers
  );
}

export async function getProductByCategory(companyId, category, token) {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };

  return await get(
    `${config.urlApi}/product/getProductByCategory?companyId=${companyId}&category=${category}`,
    headers
  );
}

export async function getProductBySubcategory(subcategory, companyId) {
  const headers = {
    "Content-Type": "application/json",
  };

  return await get(
    `${config.urlApi}/product/getProductBySubcategory?subcategory=${subcategory}&companyId=${companyId}`,
    headers
  );
}

async function getProductByIdCompany(id, token) {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };

  return await get(
    `${config.urlApi}/product/getProductByIdCompany?id=${id}`,
    headers
  );
}

async function getPaginateProducts(
  companyId,
  limit,
  page,
  order,
  orderBy,
  searchText,
  searchField,
  token
) {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };

  return await get(
    `${config.urlApi}/product/getPaginateProducts?companyId=${companyId}&limit=${limit}&page=${page}&order=${order}&orderBy=${orderBy}&searchText=${searchText}&searchField=${searchField}`,
    headers
  );
}

export async function getProductByMonth(date, companyId) {
  const headers = {
    "Content-Type": "application/json",
  };

  return await get(
    `${config.urlApi}/product/getProductByMonth?month=${date}&companyId=${companyId}`,
    headers
  );
}

export async function deleteImg(fileName, token) {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };
  return await Delete(`${config.urlApi}/s3/delete`, headers, {
    fileName,
  });
}

export async function resizeImg(data, token) {
  const headers = {
    authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  const body = { file: data };
  return await post(`${config.urlApi}/tinify/resize`, headers, body);
}

export async function compressImg(data, token) {
  const headers = {
    authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  const body = { file: data };
  return await post(`${config.urlApi}/tinify/compress`, headers, body);
}

export async function uploadImg(data, path, type, alt, token, companyId) {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };

  const body = { path: `${companyId}/${path}`, data, type };
  return await post(`${config.urlApi}/s3/upload`, headers, body).then(
    async (res) => {
      await createHistory({
        url: res.Location,
        companyId: companyId,
        alt: alt,
      }).catch((err) => console.log(err));
      return res;
    }
  );
}

export async function uploadImgFromBase64(
  data,
  path,
  type,
  alt,
  token,
  companyId
) {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };

  const body = { path: `${companyId}/${path}`, data, type };
  return await post(`${config.urlApi}/s3/uploadFromBase64`, headers, body).then(
    async (res) => {
      await createHistory({
        url: res.Location,
        companyId: companyId,
        alt: alt,
      }).catch((err) => console.log(err));
      return res;
    }
  );
}

export async function uploadVideoFromBase64(
  data,
  path,
  type,
  alt,
  token,
  companyId
) {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };

  const body = { path: `${companyId}/${path}`, data, type };
  return await post(
    `${config.urlApi}/s3/uploadVideoFromBase64`,
    headers,
    body
  ).then(async (res) => {
    await createHistory({
      url: res.Location,
      companyId: companyId,
      alt: alt,
    }).catch((err) => console.log(err));
    return res;
  });
}

async function create(product, token) {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };
  const body = {
    data: { ...product },
  };
  return await post(`${config.urlApi}/product/create`, headers, body);
}

async function update(product, token) {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };
  const body = {
    data: { ...product },
  };
  return await put(`${config.urlApi}/product/update`, headers, body);
}

async function DeleteProduct(product, token) {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };
  const body = {
    data: { ...product },
  };
  return await Delete(`${config.urlApi}/product/delete`, headers, body);
}

async function setShowReview({ reviewId, show }) {
  const headers = {
    "Content-Type": "application/json",
  };
  return await put(`${config.urlApi}/product/showReview`, headers, {
    reviewId,
    show,
  });
}
export async function getProductByName(name, companyId) {
  const headers = {
    "Content-Type": "application/json",
  };

  return await get(
    `${config.urlApi}/product/getProductByName?name=${name}&id=${companyId}`,
    headers
  );
}

export async function createAdditional(additional) {
  const headers = {
    "Content-Type": "application/json",
    // authorization: `Bearer ${token}`,
  };
  const body = {
    data: { ...additional },
  };
  return await post(`${config.urlApi}/additional/`, headers, body);
}

export async function deleteAdditional(additional) {
  const headers = {
    "Content-Type": "application/json",
    // authorization: `Bearer ${token}`,
  };
  const body = {
    data: { ...additional },
  };
  return await Delete(`${config.urlApi}/additional/`, headers, body);
}

export async function getAdditionals(companyId) {
  const headers = {
    "Content-Type": "application/json",
  };

  return await get(
    `${config.urlApi}/additional/companyId?id=${companyId}`,
    headers
  );
}

export async function updateAdditional(additional) {
  const headers = {
    "Content-Type": "application/json",
    // authorization: `Bearer ${token}`,
  };
  const body = {
    data: { ...additional },
  };
  return await put(`${config.urlApi}/additional/`, headers, body);
}

export async function massiveProducts(
  products,
  priceChangeType,
  priceValue,
  stockValue,
  rewardPointsChangeType,
  rewardPointsValue,
  visibility,
  changeVisibility,
  subsidiaries,
  companyId
) {
  const headers = {
    "Content-Type": "application/json",
    // authorization: `Bearer ${token}`,
  };
  return await post(`${config.urlApi}/massiveChanges`, headers, {
    data: {
      products,
      priceChangeType,
      priceValue,
      stockValue,
      rewardPointsChangeType,
      rewardPointsValue,
      visibility,
      changeVisibility,
      subsidiaries,
      companyId,
    },
  });
}

export async function importProducts(products, companyId) {
  const headers = {
    "Content-Type": "application/json",
    // authorization: `Bearer ${token}`,
  };
  const body = {
    data: products,
  };
  return await post(
    `https://api.lumarketo.cl/importProducts?companyId=${companyId}`,
    headers,
    body
  );
}

export async function updateCategoryToProducts(data) {
  const headers = {
    "Content-Type": "application/json",
    // authorization: `Bearer ${token}`,
  };
  return await put(
    "https://api.lumarketo.cl/updateCategoryToProducts",
    headers,
    { data }
  );
}

const productService = {
  getProductByName,
  getStatus,
  getProductById,
  getProductByIdCompany,
  deleteImg,
  uploadImg,
  uploadImgFromBase64,
  uploadVideoFromBase64,
  resizeImg,
  create,
  update,
  DeleteProduct,
  getProductByBrand,
  getProductByCategory,
  getProductBySubcategory,
  compressImg,
  setShowReview,
  createAdditional,
  getAdditionals,
  updateAdditional,
  deleteAdditional,
  updateCategoryToProducts,
  getPaginateProducts,
  massiveProducts,
};

export default productService;
