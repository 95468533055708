import config from "../config";
import { get, post, put, Delete } from "../http";

async function getById(id, token) {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };

  return await get(
    `${config.shippingServiceMicroserviceUrlApi}/shipping/?id=${id}`,
    headers
  );
}

async function getByIdCompany(id, token) {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };

  return await get(
    `${config.shippingServiceMicroserviceUrlApi}/shipping/all?idCompany=${id}`,
    headers
  );
}

async function create(data, token) {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };
  const body = {
    data,
  };

  return await post(
    `${config.shippingServiceMicroserviceUrlApi}/shipping/`,
    headers,
    body
  );
}

async function update(data, token) {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };

  const body = {
    data,
  };

  return await put(
    `${config.shippingServiceMicroserviceUrlApi}/shipping/?id=${data._id}`,
    headers,
    body
  );
}

async function remove(id, token) {
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };

  return await Delete(
    `${config.shippingServiceMicroserviceUrlApi}/shipping/?id=${id}`,
    headers
  );
}

async function getStatus() {
  const headers = {
    "Content-Type": "application/json",
  };
  return await get(
    `${config.shippingServiceMicroserviceUrlApi}/system/status`,
    headers
  );
}

async function getDeliveryCabify(orderId) {
  const headers = {
    "Content-Type": "application/json",
  };
  return await get(
    `${config.shippingServiceMicroserviceUrlApi}/shipping/cabify/getDelivery?orderId=${orderId}`,
    headers,
    {},
    true
  );
}
async function getDeliveryPedidosya(orderId) {
  const headers = {
    "Content-Type": "application/json",
  };
  return await get(
    `${config.shippingServiceMicroserviceUrlApi}/shipping/pedidosya/getDelivery?orderId=${orderId}`,
    headers,
    {},
    true
  );
}
export async function getPedidosyaToken(companyId, subsidiaryId) {
  const headers = {
    "Content-Type": "application/json",
  };

  let query = `companyId=${companyId}`;
  if (subsidiaryId) query += `&subsidiaryId=${subsidiaryId}`;

  return await get(
    // `http://localhost:3500/dev/serverless-shipping/shipping-ms/shipping/pedidosya/getToken?${query}`,
    `${config.shippingServiceMicroserviceUrlApi}/shipping/pedidosya/getToken?${query}`,
    headers
  );
}

export async function setPedidosyaToken(
  companyId,
  token,
  isTest,
  subsidiaryId
) {
  const headers = {
    "Content-Type": "application/json",
  };

  let query = `companyId=${companyId}`;
  if (subsidiaryId) query += `&subsidiaryId=${subsidiaryId}`;

  return await post(
    // `http://localhost:3500/dev/serverless-shipping/shipping-ms/shipping/pedidosya/setToken?${query}`,
    `${config.shippingServiceMicroserviceUrlApi}/shipping/pedidosya/setToken?${query}`,
    headers,
    { companyId, token, isTest }
  );
}

export async function removePedidosyaToken(companyId) {
  const headers = {
    "Content-Type": "application/json",
  };
  return await post(
    `${config.shippingServiceMicroserviceUrlApi}/shipping/pedidosya/removeToken`,
    headers,
    { companyId }
  );
}

export async function getDeliveryUber(orderId) {
  const headers = {
    "Content-Type": "application/json",
  };
  return await get(
    `${config.shippingServiceMicroserviceUrlApi}/shipping/uber/getDelivery?orderId=${orderId}`,
    headers,
    {},
    true
  );
}

const shippingService = {
  getByIdCompany,
  getById,
  create,
  remove,
  update,
  getStatus,
  getDeliveryCabify,
  getDeliveryUber,
  getDeliveryPedidosya,
  getPedidosyaToken,
  removePedidosyaToken,
};

export default shippingService;
