export const getSuggestions = (
  collections,
  brands,
  categories,
  products,
  useRoutes = true
) => {
  const result = [];
  if (useRoutes)
    result.push(
      ...[
        { label: "Home", value: "/" },
        { label: "Pedir", value: "/pedir" },
        { label: "Cuenta", value: "/mi-cuenta" },
        { label: "Pedidos", value: "/pedidos" },
        { label: "Login", value: "/acceder" },
        { label: "Registrar", value: "/registrar" },
        { label: "Menu QR", value: "/menuqr" },
      ]
    );
  categories?.forEach((category) => {
    result.push({
      label: category.Name,
      value: `/pedir?seccion=${category.Path}`,
    });
  });
  if (products && products.length > 0)
    products.forEach((product) => {
      if (product.Children && product.Children.length > 0)
        product.Children.forEach((child) => {
          result.push({
            label: `${product.Name} - ${child.Size}`,
            value: `/pedir?sku=${child.SKU}`,
          });
        });
    });
  return result;
};
export const getBrandName = (path, brands) => {
  const found = brands.find((brand) => brand.Path === path);
  return found ? found.Name : "Sin marca";
};
export const getCategoryName = (path, categories) => {
  const found = categories.find((category) => category.Path === path);
  return found ? found.Name : "Sin nombre";
};

export const getSubcategoryName = (path, categories) => {
  var found = undefined;
  categories.forEach((c) =>
    c.Category.forEach((s) => {
      if (s.Path === path) found = s;
    })
  );
  return found ? found.Name : "Sin categoría";
};

export const sleep = (time) =>
  new Promise((resolve) => setTimeout(resolve, time));

export const desc = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};
export const getSorting = (a, b, order, orderBy) => {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
};
